import React, { useEffect, useState } from "react";
import logo from "../assets/new images/logo.svg";
import { useHistory } from "react-router-dom";
import { useQuery } from "../hooks";
import API from "../api";
import checkGif from "../assets/new images/checkGif.gif";

function AddExtraCost() {
  let query = useQuery();
  const history = useHistory();
  const [montoExtra, setMontoExtra] = useState(""); // Estado para almacenar el monto extra
  const [formData, setFormData] = useState({});
  const [showError, setShowError] = useState("");
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const showConfirmationModal = (message) => {
    setConfirmationMessage(message);
    setShowModal(true);

    // Ocultar el modal después de unos segundos (por ejemplo, 3 segundos)
    setTimeout(() => {
      setShowModal(false);
      setConfirmationMessage("");
      history.push("/"); // Redirigir a la pantalla principal después de ocultar el modal
    }, 3000); // 3000 milisegundos = 3 segundos
  };

  const formatNumber = (value) => {
    // Verificar si hay un valor en el input
    const hasValue = value !== "";
    // Convertir el valor a string y agregar separador de miles
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // Retornar el valor formateado con el símbolo de Gs. si hay un valor, de lo contrario, mostrar un placeholder
    return hasValue ? `Gs. ${formattedValue}` : "";
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // Eliminar cualquier caracter que no sea un dígito
    const cleanValue = value.replace(/\D/g, "");
    // Actualizar el estado con el valor limpio y formateado
    setMontoExtra(cleanValue);
  };

  const getCurrentBooking = async () => {
    try {
      const currentBooking = await API.getReservation(
        query.get("reservation").replace("/", "")
      );
      console.log(currentBooking);
      setData(currentBooking);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getCurrentBooking();
  }, []);

  const updateData = () => {
    const valorConSlash = query.get("reservation");
    const valorSinSlash = valorConSlash.replace("/", ""); // Eliminar el '/'
    const valorEntero = parseInt(valorSinSlash, 10); // Convertir a entero
    const formDataToSend = new FormData();
    formDataToSend.append("reservation_id", valorEntero);
    formDataToSend.append(
      "extra_cost",
      montoExtra === "" ? 0 : parseInt(montoExtra)
    );
    setFormData(formDataToSend);
    console.log(valorEntero);
  };

  const handleSubmit = async () => {
    try {
      const response = await API.sendExtraCost(formData);
      console.log("Costo asignado:", response);
      setShowError("");
      showConfirmationModal("Se ha confirmado la reserva correctamente.");
      return true;
      // Aquí puedes manejar la respuesta del servidor, mostrar una notificación, redirigir al usuario, etc.
    } catch (error) {
      console.error(error.detail);
      setShowError(error.detail);
      return false;
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje de error al usuario.
    }
  };

  useEffect(() => {
    updateData();
  }, [montoExtra]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img src={logo} alt="Logo" style={{ width: 164, marginBottom: 40 }} />
      <h2
        style={{
          color: "var(--1, #CE2F15)",
          textAlign: "center",
          fontSize: 25,
          fontWeight: "700",
          lineHeight: "33px",
          marginBottom: "20px",
          fontFamily: "Nunito Sans",
        }}
      >
        {data.in_asuncion
          ? "¡Confirma el pedido!"
          : "¡Confirma el coste del pedido!"}
      </h2>
      <p
        style={{
          color: "#000",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "400",
          lineHeight: "21px",
          marginBottom: "30px",
        }}
      >
        {data.in_asuncion
          ? "Presiona el botón para confirmar el pedido"
          : "En caso de determinar que el pedido deba tener un coste extra, puedes agregarlo a continuación."}
      </p>
      {!data.in_asuncion && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          <label
            style={{
              color: "#000",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "600",
              lineHeight: "21px",
              marginBottom: "8px",
            }}
            htmlFor="montoExtra"
          >
            Agrega el monto extra a cobrar
          </label>
          <input
            style={{
              borderRadius: "3px",
              border: "1px solid #D2D2D2",
              background: "#FAFAFA",
              paddingLeft: "25px",
              textAlign: "right",
              paddingRight: 10,
              color: "#262626",
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
              width: 264,
              padding: 9,
            }}
            type="text"
            id="montoExtra"
            name="montoExtra"
            value={formatNumber(montoExtra)}
            onChange={handleChange}
          />
        </div>
      )}
      <button
        style={{
          borderRadius: "4px",
          background: "var(--1, #CE2F15)",
          color: "#FFF",
          fontSize: 16,
          fontWeight: "600",
          lineHeight: "20px",
          width: 264,
          margin: 0,
          padding: 9,
        }}
        onClick={handleSubmit}
      >
        {data.in_asuncion ? "Aceptar" : "Enviar y Aceptar"}
      </button>
      <p
        style={{
          display: !showError ? "none" : "block",
          color: "#CE2F15",
          fontSize: 16,
          fontWeight: "600",
          lineHeight: "14px",
          marginTop: 0,
        }}
      >
        {showError}
      </p>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              padding: 20,
              borderRadius: 8,
              maxWidth: 400,
              textAlign: "center",
              color: "#27A576",
              fontSize: 18,
              fontWeight: "700",
              lineHeight: "22px",
              margin: 20,
            }}
          >
            <img src={checkGif} alt="Check Image" style={{ width: 150 }} />
            <p>{confirmationMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddExtraCost;
