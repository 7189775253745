import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./BookingForm.css"; // Estilo CSS para el formulario
import MenuCard from "../components/MenuCard";
import CustomButton from "../components/CustomButton";
import calendar from "../assets/new images/calendar.svg";
import clock from "../assets/new images/clock.svg";
import CustomInput from "../components/CustomInput";
import minus from "../assets/new images/minus-circle.svg";
import plus from "../assets/new images/plus-circle.svg";
import Calendar from "react-calendar";
import Modal from "react-modal";
import MapGl from "../components/MapGl";
import API from "../api";
import { useHistory } from "react-router-dom";
import loader from "../assets/new images/buscoChefLoader.gif";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CustomAppBar from "../components/CustomAppBar";

const BookingForm = (props) => {
  // Verificar si existe props.location.state
  const menuData = props.location.state.menuData;
  const data = props.location.state.data;
  const [step, setStep] = useState(1); // Estado para controlar el paso actual
  const [showCalendar, setShowCalendar] = useState(false);
  const today = new Date(); // Obtener la fecha actual
  const anticipationHours = menuData.anticipation_time; // Horas de anticipación desde menuData
  const minDate = new Date(
    today.getTime() + anticipationHours * 60 * 60 * 1000
  );
  const [selectedDate, setSelectedDate] = useState(minDate);
  const [showSelectedDate, setShowSelectedDate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [peopleCount, setPeopleCount] = useState(menuData.min_diners);
  const [placeType, setPlaceType] = useState("");
  const [eventType, setEventType] = useState(1);
  const [eventAtmosphere, setEventAtmosphere] = useState(1);
  const [address, setAddress] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [localities, setLocalities] = useState([]);
  const [filteredLocalities, setFilteredLocalities] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesFiles, setImagesFiles] = useState([]);
  const [generalComments, setGeneralComments] = useState("");
  const [fullname, setFullname] = useState("");
  const [ci, setCi] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
  });
  const [inAsuncion, setInAsuncion] = useState(false);
  const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  const [requiredFieldsComplete2, setRequiredFieldsComplete2] = useState(false);
  const [showRequireModal, setShowRequireModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const history = useHistory();

  const checkReservation = async () => {
    const formCheck = {
      date: formatDateForBack(selectedDate),
      provider: data.id,
    };
    try {
      const response = await API.sendReservationCheck(formCheck);
      console.log("La fecha esta libre:", response);
      setShowError("");
      return true;
      // Aquí puedes manejar la respuesta del servidor, mostrar una notificación, redirigir al usuario, etc.
    } catch (error) {
      console.error(error.detail);
      setShowError(error.detail);
      return false;
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje de error al usuario.
    }
  };
  useEffect(() => {
    checkReservation();
  }, [selectedDate]);

  useEffect(() => {
    const fetchCitiesAndLocalities = async () => {
      try {
        const cities = await API.getCities();
        setCities(cities);
        setLoading(false);
        const localities = await API.getLocalities();
        setLocalities(localities);
      } catch (error) {
        console.error("Error fetching service types:", error);
        setLoading(false);
      }
    };

    fetchCitiesAndLocalities();
  }, []);

  useEffect(() => {
    if (city && localities.length > 0) {
      const filteredLocalities = localities.filter(
        (locality) => locality.city.id == city
      );
      setFilteredLocalities(filteredLocalities);
    }
  }, [city, localities]);

  function isValidEmail(email) {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Esta función se pasará como prop al componente MapGl
  const handleCoordinatesChange = (newCoordinates, isInAsuncion) => {
    setCoordinates(newCoordinates);
    console.log("¿Está en Asunción?", isInAsuncion);
    setInAsuncion(isInAsuncion);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImagesFiles(files);
    const images = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...images]);
  };

  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  // Función para manejar el paso siguiente
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  // Función para manejar el paso anterior
  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses van de 0 a 11 en JavaScript
    const year = date.getFullYear();

    // Agrega ceros a la izquierda si el día o el mes son menores a 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const formatDateForBack = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses van de 0 a 11 en JavaScript
    const year = date.getFullYear();

    // Agrega ceros a la izquierda si el día o el mes son menores a 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBlur = () => {
    if (peopleCount < menuData.min_diners) {
      setPeopleCount(menuData.min_diners);
      alert(
        `No puedes disminuir la cantidad de comensales: ${menuData.min_diners}`
      );
    }
  };

  const [formData, setFormData] = useState({
    // Estado para almacenar los datos de reserva
    menu: null,
    date: null,
    time_of_day: null,
    diners_qty: null,
    total: null,
    service_types: [],
    event_environment: "",
    address: "",
    city: null,
    locality: null,
    obs: "",
    name: "",
    ruc: "",
    email: "",
    phone: "",
    location: {
      latitude: null,
      longitude: null,
    },
    uploaded_images: [],
  });

  // Función para enviar los datos del formulario al backend
  const sendReservation = async () => {
    try {
      const response = await API.sendReservation(formData);
      console.log("Reserva realizada con éxito:", response);
      return true;
    } catch (error) {
      console.error("Error al realizar la reserva:", error.detail);
      setShowError(error.detail);
      return false;
    }
  };

  // Función para manejar el envío final del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await sendReservation();
    if (result) {
      history.push("/notification");
    } else {
      console.log(formData);
    }
  };

  const updateData = async () => {
    const formDataToSend = new FormData();

    data.service_types.forEach((service) => {
      formDataToSend.append("service_types", service.id);
    });

    // Agregar las imágenes al FormData
    imagesFiles.forEach((file, index) => {
      formDataToSend.append(`uploaded_images`, file);
    });

    // Agregar los campos al FormData
    formDataToSend.append("menu", menuData.id);
    formDataToSend.append("date", formatDateForBack(selectedDate));
    formDataToSend.append("time_of_day", selectedSchedule);
    formDataToSend.append("diners_qty", peopleCount);
    formDataToSend.append("total", menuData.price * peopleCount);
    formDataToSend.append("event_location", placeType);
    formDataToSend.append("event_type", eventType);
    formDataToSend.append("event_environment", eventAtmosphere);
    formDataToSend.append("address", address);
    formDataToSend.append("city", city);
    formDataToSend.append("locality", neighborhood);
    formDataToSend.append("obs", generalComments);
    formDataToSend.append("name", fullname);
    formDataToSend.append("ruc", ci);
    formDataToSend.append("email", email);
    formDataToSend.append("phone", whatsapp);
    formDataToSend.append("location.latitude", coordinates.latitude);
    formDataToSend.append("location.longitude", coordinates.longitude);
    formDataToSend.set("in_asuncion", inAsuncion);
    setFormData(formDataToSend);
    console.log("Datos de reserva:", formDataToSend);
  };

  // Función para verificar si todos los campos requeridos del step1 están completos
  const checkRequiredFields = () => {
    // Verificar aquí si todos los campos requeridos están completos
    const requiredFieldsAreComplete =
      selectedDate &&
      selectedSchedule &&
      peopleCount &&
      placeType &&
      eventType &&
      eventAtmosphere &&
      address &&
      city &&
      neighborhood &&
      coordinates.latitude &&
      coordinates.longitude;
    // Agrega más campos requeridos aquí según sea necesario

    setRequiredFieldsComplete(requiredFieldsAreComplete);
  };

  // Llama a la función checkRequiredFields cada vez que uno de los campos requeridos cambie
  useEffect(() => {
    checkRequiredFields();
  }, [
    selectedDate,
    selectedSchedule,
    peopleCount,
    placeType,
    eventType,
    eventAtmosphere,
    address,
    city,
    neighborhood,
    coordinates,
  ]);

  // Función para verificar si todos los campos requeridos del step2 están completos
  const checkRequiredFieldsStep2 = () => {
    // Verificar aquí si todos los campos requeridos están completos
    const requiredFieldsAreComplete =
      fullname && ci && email && !emailError && whatsapp;
    // Agrega más campos requeridos aquí según sea necesario

    setRequiredFieldsComplete2(requiredFieldsAreComplete);
  };

  // Llama a la función checkRequiredFields cada vez que uno de los campos requeridos cambie
  useEffect(() => {
    checkRequiredFieldsStep2();
  }, [fullname, ci, email, whatsapp]);

  const handleOnSearch = (string, results) => {
    // Implementa la lógica de búsqueda aquí si lo necesitas
    console.log(string, results);
  };

  const handleOnSelectCity = (city) => {
    setCity(city.id);
    console.log(city);
    // Aquí podrías filtrar las localidades por la ciudad seleccionada si es necesario
    const filteredLocs = localities.filter((loc) => loc.city.id === city.id);
    setFilteredLocalities(filteredLocs);
  };

  const handleOnSelectNeighborhood = (neighborhood) => {
    setNeighborhood(neighborhood.id);
  };

  const formatCityResult = (city) => {
    return <span>{city.name}</span>;
  };

  const formatNeighborhoodResult = (neighborhood) => {
    return <span>{neighborhood.name}</span>;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: windowWidth >= 1024 ? "center" : "flex-start",
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <CustomAppBar
          onClick={() => {
            if (step === 1) {
              history.goBack();
            } else if (step === 2) {
              updateData();
              setStep(1);
            } else if (step === 3) {
              updateData();
              setStep(2);
            }
          }}
        />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: windowWidth >= 1024 ? "50%" : "100%",
          }}
        >
          <img src={loader} alt="loader" style={{ width: 182 }} />
          <p
            style={{
              fontSize: 18,
              fontWeight: "700",
              lineHeight: " 22px",
              color: "#CE2F15",
            }}
          >
            Cargando...
          </p>
        </div>
      ) : (
        <div
          className="booking-form"
          style={{ width: windowWidth >= 1024 ? "50%" : "100%" }}
        >
          <Modal
            style={{
              content: {
                position: "absolute",
                left: 0,
                top: "auto",
                width: windowWidth >= 1024 ? "50%" : "100%",
                borderRadius: "50px 50px 0px 0px",
                backgroundColor: "#FFF",
                border: "none",
                display: "flex",
                flexDirection: "column",
                inset: "auto 40px 0px 0px",
                zIndex: 999,
                marginLeft: "auto",
                marginRight: "auto",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9,
              },
            }}
            ariaHideApp={false}
            isOpen={showRequireModal}
            onRequestClose={() => setShowRequireModal(false)}
          >
            <h2
              style={{
                fontSize: 18,
                fontWeight: "700",
                color: "#000",
                fontFamily: "Nunito Sans",
              }}
            >
              Campos requeridos incompletos
              <span style={{ color: "#CE2F15" }}>*</span>{" "}
            </h2>
            <p style={{ fontSize: 14, fontWeight: "400", color: "#262626" }}>
              Por favor completa todos los campos requeridos antes de continuar.
            </p>
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 17,
                backgroundColor: "#CE2F15",
                color: "#FFF",
                fontSize: 18,
                fontWeight: "700",
                lineHeight: "22px",
                borderRadius: 8,
              }}
              onClick={() => setShowRequireModal(false)}
            >
              Cerrar
            </button>
          </Modal>
          <div className="steps">
            <div
              className={`step-button ${step === 1 ? "active" : ""}`}
              onClick={() => {
                updateData();
                setStep(1);
              }}
            >
              <span className={`steps-text ${step === 1 ? "active" : ""}`}>
                Datos del evento
              </span>
              <div className="underline"></div>
            </div>
            <div
              className={`step-button ${step === 2 ? "active" : ""}`}
              onClick={() => {
                if (!showError) {
                  if (requiredFieldsComplete) {
                    updateData();
                    setStep(2);
                  } else {
                    setShowRequireModal(true);
                  }
                }
              }}
            >
              <span className={`steps-text ${step === 2 ? "active" : ""}`}>
                Confirma tu pedido
              </span>
              <div className="underline"></div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <div className="step">
                <h2 className="bookingTitle">Datos del evento</h2>
                <div style={{ padding: "20px 0px" }}>
                  <MenuCard
                    menuData={menuData}
                    key={menuData.id}
                    showDes={false}
                    nolink={true}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <CustomButton
                  label="Fecha del evento"
                  require={true}
                  text={
                    showSelectedDate
                      ? formatDate(selectedDate)
                      : "Seleccionar fecha"
                  }
                  icon={calendar}
                  onPress={(e) => {
                    e.preventDefault();
                    setShowCalendar(true);
                  }}
                />
                <p
                  style={{
                    display: !showError ? "none" : "block",
                    color: "#CE2F15",
                    fontSize: 16,
                    fontWeight: "600",
                    lineHeight: "14px",
                    marginTop: 0,
                  }}
                >
                  {showError}
                </p>
                {showCalendar && (
                  <div
                    className="calendar-container"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Calendar
                      onChange={handleDateChange}
                      value={selectedDate}
                      onClickDay={() => {
                        setShowCalendar(false);
                        setShowSelectedDate(true);
                      }}
                      minDate={minDate}
                      locale="es-LA"
                    />
                    <button
                      style={{
                        display: "none",
                        borderRadius: 4,
                        backgroundColor: "#262626",
                        width: "100%",
                        color: "#fff",
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        setShowCalendar(false);
                        setShowSelectedDate(true);
                      }}
                    >
                      Guardar Fecha
                    </button>
                  </div>
                )}

                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <CustomButton
                  label="Horario"
                  require={true}
                  text={
                    selectedSchedule
                      ? selectedSchedule === 1
                        ? "11:00 a.m. - 14:00 p.m."
                        : selectedSchedule === 2
                        ? "15:30 p.m. - 17:30 p.m."
                        : "18:30 p.m. - 21:30 p.m."
                      : "Seleccionar horario"
                  }
                  icon={clock}
                  onPress={(e) => {
                    e.preventDefault();
                    handleOpenModal();
                  }}
                />
                <Modal
                  isOpen={showModal}
                  onRequestClose={handleCloseModal}
                  contentLabel="Selecciona el horario de servicio"
                  style={{
                    content: {
                      position: "fixed",
                      left: 0,
                      top: "auto",
                      width: windowWidth >= 1024 ? "50%" : "100%",
                      borderRadius: "50px 50px 0px 0px",
                      backgroundColor: "#FFF",
                      border: "none",
                      display: "flex",
                      flexDirection: "column",
                      inset: "auto 40px 0px 0px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    },
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 9,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <h2
                    style={{
                      fontSize: 18,
                      fontWeight: "700",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    Selecciona el horario de servicio
                  </h2>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        fontFamily: "Nunito Sans",
                      }}
                    >
                      Mediodía:
                    </h2>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <input
                        id="mediodia"
                        style={{ accentColor: "#CE2F15" }}
                        type="radio"
                        value={1}
                        checked={selectedSchedule === 1}
                        onChange={() => setSelectedSchedule(1)}
                        disabled={!data.availability.noon}
                      />
                      <label
                        htmlFor="mediodia"
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          lineHeight: "21px",
                          color: "#262626",
                        }}
                      >
                        11:00 a.m. - 14:00 p.m.
                      </label>
                    </div>
                    <h2
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        fontFamily: "Nunito Sans",
                      }}
                    >
                      Tarde:
                    </h2>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <input
                        id="tarde"
                        style={{ accentColor: "#CE2F15" }}
                        type="radio"
                        value={2}
                        checked={selectedSchedule === 2}
                        onChange={() => setSelectedSchedule(2)}
                        disabled={!data.availability.afternoon}
                      />
                      <label
                        htmlFor="tarde"
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          lineHeight: "21px",
                          color: "#262626",
                        }}
                      >
                        15:30 p.m. - 17:30 p.m.
                      </label>
                    </div>
                    <h2
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        fontFamily: "Nunito Sans",
                      }}
                    >
                      Noche:
                    </h2>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <input
                        id="noche"
                        style={{ accentColor: "#CE2F15" }}
                        type="radio"
                        value={3}
                        checked={selectedSchedule === 3}
                        onChange={() => setSelectedSchedule(3)}
                        disabled={!data.availability.night}
                      />
                      <label
                        htmlFor="noche"
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          lineHeight: "21px",
                          color: "#262626",
                        }}
                      >
                        18:30 p.m. - 21:30 p.m.
                      </label>
                    </div>
                    {/* Agrega más elementos de la lista según sea necesario */}
                  </div>
                  <button
                    style={{
                      borderRadius: 4,
                      backgroundColor: "#262626",
                      width: "100%",
                      color: "#fff",
                      padding: 20,
                      fontSize: 16,
                      fontWeight: "600",
                      marginTop: 40,
                    }}
                    onClick={handleCloseModal}
                  >
                    Guardar horario
                  </button>
                </Modal>

                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <label htmlFor="cantidadPersonas" className="input-label">
                    Cantidad de personas <span className="require">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 5,
                      border: "0.649px solid #E5E5E5",
                      //width: 108,
                      height: 35,
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "none",
                        width: 15,
                        height: 15,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (peopleCount > menuData.min_diners) {
                          setPeopleCount((prev) =>
                            setPeopleCount(parseInt(prev) - 1)
                          );
                        }
                      }}
                    >
                      <img src={minus} alt="minus" />
                    </button>
                    <input
                      style={{
                        textAlign: "center",
                        border: "0.649px solid #E5E5E5",
                        borderLeft: "none",
                        borderRight: "none",
                        height: 35,
                        width: 50,
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#262626",
                        backgroundColor: "#FFF",
                      }}
                      type="number"
                      value={peopleCount}
                      onChange={(e) => {
                        setPeopleCount(e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "none",
                        width: 15,
                        height: 15,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        //if (peopleCount < menuData.max_diners) {
                        setPeopleCount((prev) =>
                          setPeopleCount(parseInt(prev) + 1)
                        );
                        //}
                      }}
                    >
                      <img src={plus} alt="plus" />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 20,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "700",
                        color: "#000000",
                      }}
                    >
                      Precio total
                    </div>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "800",
                        color: "#000000",
                      }}
                    >
                      {`Gs. ${formatPrice(menuData.price * peopleCount)}`}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: "600",
                      color: "#969B91",
                      width: "60%",
                      marginBottom: 4,
                    }}
                  >
                    Una vez que se confirme la dirección de tu evento, podrían
                    haber costos adicionales
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div style={{ marginTop: 20 }}>
                  <label className="input-label">
                    Lugar del evento <span className="require">*</span>
                  </label>
                  <div
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="aireLibre"
                      name="lugarEvento"
                      value={1}
                      checked={placeType == 1}
                      onChange={(event) => setPlaceType(event.target.value)}
                      style={{ marginRight: "12px", accentColor: "#CE2F15" }}
                    />
                    <label
                      htmlFor="aireLibre"
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        lineHeight: "18px",
                        color: "#262626",
                      }}
                    >
                      Aire libre
                    </label>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="techoCerrado"
                      name="lugarEvento"
                      value={2}
                      checked={placeType == 2}
                      onChange={(event) => setPlaceType(event.target.value)}
                      style={{ marginRight: "12px", accentColor: "#CE2F15" }}
                    />
                    <label
                      htmlFor="techoCerrado"
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        lineHeight: "18px",
                        color: "#262626",
                      }}
                    >
                      Techo cerrado
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div
                  style={{
                    marginTop: 30,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label className="input-label">
                    Tipo de evento <span className="require">*</span>
                  </label>
                  <select
                    style={{
                      padding: "9px 15px",
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#000000",
                      border: "0.649px solid #E5E5E5",
                      borderRadius: 5,
                      width: 130,
                    }}
                    value={eventType}
                    onChange={(event) => setEventType(event.target.value)}
                  >
                    <option value={1}>Cumpleaños</option>
                    <option value={2}>Evento social</option>
                    <option value={3}>Evento corporativo</option>
                  </select>
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div
                  style={{
                    marginTop: 40,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label className="input-label">
                    Ambiente del evento <span className="require">*</span>
                  </label>
                  <select
                    style={{
                      padding: "9px 15px",
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#000000",
                      border: "0.649px solid #E5E5E5",
                      borderRadius: 5,
                      width: 130,
                    }}
                    value={eventAtmosphere}
                    onChange={(event) => setEventAtmosphere(event.target.value)}
                  >
                    <option value={1}>Quincho</option>
                    <option value={2}>Salón social</option>
                    <option value={3}>Terraza</option>
                    <option value={4}>Salón de eventos</option>
                    <option value={5}>Cocina particular</option>
                  </select>
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div style={{ height: 10 }} />
                <CustomInput
                  label="Dirección"
                  require={true}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  value={address}
                />
                <div
                  style={{
                    marginTop: 30,
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="ciudad" className="input-label">
                    Ciudad <span className="require">*</span>
                  </label>
                  <ReactSearchAutocomplete
                    items={cities}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelectCity}
                    formatResult={formatCityResult}
                    placeholder="Buscar ciudad..."
                    styling={{
                      zIndex: 9,
                      width: "100%",
                      padding: 10,
                      border: "1px solid #E5E5E5",
                      borderRadius: "8px",
                      fontSize: 14,
                      color: "#000",
                    }}
                  />
                </div>
                {city && (
                  <div
                    style={{
                      marginTop: 30,
                      marginBottom: 20,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <label htmlFor="barrio" className="input-label">
                      Barrio <span className="require">*</span>
                    </label>

                    <ReactSearchAutocomplete
                      items={filteredLocalities}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelectNeighborhood}
                      formatResult={formatNeighborhoodResult}
                      placeholder="Buscar barrio..."
                      styling={{
                        marginTop: 10,
                        zIndex: 8,
                        width: "100%",
                        padding: 10,
                        border: "1px solid #E5E5E5",
                        borderRadius: "8px",
                        fontSize: 14,
                        color: "#000",
                      }}
                    />
                  </div>
                )}
                <div style={{ marginBottom: 10 }}>
                  <label className="input-label">
                    Ubicación <span className="require">*</span>
                  </label>
                  <MapGl onCoordinatesChange={handleCoordinatesChange} />
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div style={{ margin: "20px 0", position: "relative" }}>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#000000",
                    }}
                  >
                    Fotos del lugar
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#969B91",
                      marginBottom: 10,
                    }}
                  >
                    Si no tenés a mano, podés adjuntar las fotos después
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      rowGap: 20,
                    }}
                  >
                    {selectedImages?.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Image-${index}`}
                        style={{
                          width: "50%",
                          height: 110,
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    ))}
                  </div>
                  <div
                    onClick={() =>
                      document.querySelector('input[type="file"]').click()
                    }
                    style={{
                      height: 110,
                      borderRadius: 4,
                      border: "1px dashed #ACACAC",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                      padding: 25,
                      cursor: "pointer",
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "400",
                        lineHeight: "16px",
                        color: "#ACACAC",
                      }}
                    >
                      Seleccioná la foto desde tu galería o tomale una
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 8,
                        fontWeight: "400",
                        color: "rgba(172, 172, 172, 0.50)",
                      }}
                    >
                      JPG, PNG or PDF, archivo no más de 10MB
                    </p>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#F7F4F8",
                    height: 4,
                    width: "100%",
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                    left: 0,
                  }}
                />
                <div style={{ margin: "35px 0" }}>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#000000",
                    }}
                  >
                    Comentarios generales
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#969B91",
                    }}
                  >
                    Detalles del evento, preguntas al chef, intolerancias
                    alimenticias, etc
                  </div>
                  <textarea
                    placeholder="Escribí tus comentarios"
                    value={generalComments}
                    style={{
                      border: "0.649px solid #E5E5E5",
                      borderRadius: 4,
                      width: "100%",
                      height: 100,
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                      color: "#262626",
                      marginTop: 10,
                      padding: 10,
                    }}
                    onChange={(event) => {
                      setGeneralComments(event.target.value);
                    }}
                  />
                </div>
                <button
                  style={{
                    display: showRequireModal ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: 17,
                    backgroundColor: "#CE2F15",
                    color: "#FFF",
                    fontSize: 18,
                    fontWeight: "700",
                    lineHeight: "22px",
                    borderRadius: 8,
                    position: "sticky",
                    bottom: 22,
                    zIndex: 9,
                  }}
                  type="button"
                  onClick={() => {
                    if (!showError) {
                      if (requiredFieldsComplete) {
                        updateData();
                        handleNextStep();
                      } else {
                        setShowRequireModal(true);
                      }
                    }
                  }}
                >
                  Continuar
                </button>
              </div>
            )}

            {step === 2 && (
              <div className="step">
                <h2 className="bookingTitle">Confirma tu pedido</h2>
                <MenuCard
                  menuData={menuData}
                  key={menuData.id}
                  showDes={false}
                  nolink={true}
                  resume={true}
                />
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    height: 1,
                    borderRadius: 21,
                    background: " #D9D9D9",
                  }}
                />
                <h2
                  className="bookingTitle"
                  style={{
                    fontSize: 16,
                    fontWeight: "800",
                    lineHeight: "16px",
                    color: "#262626",
                  }}
                >
                  Resumen
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                    borderRadius: "6px",
                    border: "1px solid #E9E8EA",
                    padding: 16,
                  }}
                >
                  <div className="resumen-container">
                    <span className="resumen-title">Fecha</span>
                    <span className="resumen-text">
                      {formatDate(selectedDate)}
                    </span>
                  </div>
                  <div className="resumen-container">
                    <span className="resumen-title">Horario</span>
                    <span className="resumen-text">
                      {selectedSchedule === 1
                        ? "Mediodía"
                        : selectedSchedule === 2
                        ? "Tarde"
                        : "Noche"}
                    </span>
                  </div>
                  <div className="resumen-container">
                    <span className="resumen-title">Cantidad de personas</span>
                    <span className="resumen-text">{peopleCount}</span>
                  </div>
                  <div className="resumen-container">
                    <span className="resumen-title">Lugar del evento</span>
                    <span className="resumen-text">
                      {placeType == 1 ? "Aire libre" : "Techo Cerrado"}
                    </span>
                  </div>
                  <div className="resumen-container">
                    <span className="resumen-title">Dirección</span>
                    <span className="resumen-text">{address}</span>
                  </div>
                </div>
                <div className="step" style={{ marginTop: 20 }}>
                  <h2
                    className="bookingTitle"
                    style={{
                      fontSize: 16,
                      fontWeight: "800",
                      lineHeight: "16px",
                      color: "#262626",
                    }}
                  >
                    Datos personales{" "}
                    <span
                      className="require"
                      style={{
                        fontSize: 16,
                        fontWeight: "800",
                        lineHeight: "16px",
                      }}
                    >
                      *
                    </span>
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CustomInput
                      label="Nombre y apellido"
                      require={true}
                      onChange={(event) => setFullname(event.target.value)}
                      value={fullname}
                      placeholder="Escribí tu nombre y apellido"
                    />
                    <CustomInput
                      label="CI / RUC"
                      require={true}
                      onChange={(event) => setCi(event.target.value)}
                      value={ci}
                      placeholder="Escribí tu RUC o CI"
                    />
                    <CustomInput
                      label="Correo"
                      require={true}
                      onChange={(event) => {
                        setEmail(event.target.value);
                        // Verificar si el correo electrónico es válido
                        if (!isValidEmail(event.target.value)) {
                          setEmailError(
                            "El formato del correo electrónico no es válido"
                          );
                        } else {
                          setEmailError(""); // Limpiar el mensaje de error si el correo es válido
                        }
                      }}
                      value={email}
                      placeholder="Tu correo electrónico"
                    />
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#CE2F15",
                        lineHeight: "14px",
                      }}
                    >
                      {emailError}
                    </span>
                    <CustomInput
                      label="WhatsApp"
                      require={true}
                      onChange={(event) => setWhatsapp(event.target.value)}
                      value={whatsapp}
                      placeholder="Tu número de WhatsApp. Ej +595 ..."
                    />
                  </div>
                  {/* Otros campos del paso 2 */}
                </div>
                <div
                  style={{
                    marginTop: 30,
                    height: 1,
                    borderRadius: 21,
                    background: " #D9D9D9",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "800",
                      color: "#000000",
                    }}
                  >
                    Precio total
                  </span>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "800",
                      color: "#000000",
                    }}
                  >
                    Gs. {formatPrice(menuData.price * peopleCount)}
                  </span>
                </div>
                <p
                  style={{
                    color: "#CE2F15",
                    fontSize: 16,
                    fontWeight: "600",
                    lineHeight: "14px",
                  }}
                >
                  {showError}
                </p>
                <button
                  type="submit"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: windowWidth >= 1024 ? "100%" : "90%",
                    padding: 17,
                    backgroundColor: "#CE2F15",
                    color: "#FFF",
                    fontSize: 18,
                    fontWeight: "700",
                    lineHeight: "22px",
                    borderRadius: 8,
                    position: windowWidth >= 1024 ? "relative" : "absolute",
                  }}
                  onClick={() => {
                    if (requiredFieldsComplete2) {
                      updateData();
                    } else {
                      setShowRequireModal(true);
                    }
                  }}
                >
                  Solicitar reserva
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default BookingForm;
