import React from "react";
import { Link } from "react-router-dom";
import checkGif from "../assets/new images/checkGif.gif";
import logo from "../assets/new images/logo.svg";

const PaymentConfirm = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 20,
      }}
    >
      <img
        src={logo} // Ruta del logo
        alt="Logo"
        style={{ width: 150, objectFit: "cover", marginTop: 50 }}
      />
      <img
        src={checkGif}
        alt="Check Image"
        style={{ width: 177, marginTop: 50 }}
      />
      <h1
        style={{
          color: "#27A576",
          textAlign: "center",
          fontSize: 18,
          fontWeight: "700",
          lineHeight: "22px",
        }}
      >
        ¡Pago procesado con éxito!
      </h1>
      <h2
        style={{
          color: "#262626",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "700",
          lineHeight: "21px",
          marginTop: 50,
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        venenatis eget arcu at dictum.
      </h2>
      <Link
        to="/"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 17,
          backgroundColor: "#CE2F15",
          color: "#FFF",
          fontSize: 18,
          fontWeight: "700",
          lineHeight: "22px",
          borderRadius: 8,
          textDecoration: "none",
          width: "100%",
          marginTop: 100,
        }}
      >
        Volver al inicio
      </Link>
    </div>
  );
};

export default PaymentConfirm;
