import "./CustomButton.css";
import React, { useState, useEffect } from "react";

const CustomButton = ({ label, text, icon, onPress, require }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);
  return (
    <div className="custom-button-container">
      <span
        className="button-label"
        style={{
          position: windowWidth >= 1024 ? "relative" : "absolute",
          left: windowWidth >= 1024 ? "0" : "20px",
        }}
      >
        {label}
        {require ? <span className="require">*</span> : ""}
      </span>
      <button className="custom-button" onClick={onPress}>
        {icon && <img src={icon} alt="Icon" className="button-icon" />}
        <span className="button-text">{text}</span>
      </button>
    </div>
  );
};

export default CustomButton;
