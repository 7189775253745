import React from "react";
import { useHistory } from "react-router-dom";
import arrowleft from "../assets/images/icon-arrow-left.svg";
import barlogo from "../assets/images/logo-bar.svg";
import ChevronLeft from "../assets/new icons/ChevronLeft";
import logo from "../assets/new images/logo.svg";

const Appbar = (props) => {
  let history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/*<div className="column ">
        <img
          className="ml-5 zoom"
          src={arrowleft}
          width="40px"
          height="40px"
          alt="Atras"
          onClick={goBack}
        />
  </div>*/}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          width: 37,
          height: 37,
          backgroundColor: "#f0f0f0",
          position: "absolute",
        }}
        onClick={goBack}
      >
        <ChevronLeft size={17} />
      </div>
      {/*<div className="column is-flex is-justify-content-center">
                <img
                    className=" "
                    src={barlogo}
                    width="120px"
                    height="45px"
                    alt="BuscoChef"
                />
            </div>*/}
      <img src={logo} alt="Logo" className="logo" />
      <div className="column">
        {/*La columna esta vacia, nos ayuda a crear un espacio 
                para que el navBar se vea como de dos elementos justificados 
                a la izquierda, pero en realidad son 3 elementos en flex 
                con una columna vacia*/}
      </div>
    </div>
  );
};

export default Appbar;
