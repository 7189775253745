import React, { useState, useEffect, useRef } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import myLocation from "../assets/new images/myLocation.svg";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

function MapGl({ onCoordinatesChange }) {
  const [viewport, setViewport] = useState({
    longitude: -57.5759,
    latitude: -25.2637,
    //zoom: 16,
  });
  const [markerPosition, setMarkerPosition] = useState({
    longitude: -57.5759,
    latitude: -25.2637,
    //zoom: 16,
  });

  // Función para verificar si las coordenadas están dentro de Asunción
  const isLocationInAsuncion = (latitude, longitude) => {
    // Coordenadas de Asunción, Paraguay (aproximadas)
    const minLat = -25.367;
    const maxLat = -25.187;
    const minLong = -57.685;
    const maxLong = -57.535;

    // Verificar si las coordenadas están dentro del rango de Asunción
    return (
      latitude >= minLat &&
      latitude <= maxLat &&
      longitude >= minLong &&
      longitude <= maxLong
    );
  };

  const handleMarkerDrag = (event) => {
    const newCoordinates = {
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng,
    };
    setMarkerPosition(newCoordinates);
    setViewport({
      ...viewport,
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng,
    });

    // Verificar si las coordenadas pertenecen a Asunción
    const { latitude, longitude } = newCoordinates;
    const isInAsuncion = isLocationInAsuncion(latitude, longitude);

    onCoordinatesChange(newCoordinates, isInAsuncion); // Llamar a la función del padre con las nuevas coordenadas
  };

  const handleMarkCurrentLocation = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newCoordinates = {
          latitude,
          longitude,
        };
        setViewport({
          ...viewport,
          latitude,
          longitude,
        });
        setMarkerPosition(newCoordinates);

        // Verificar si las coordenadas pertenecen a Asunción
        const isInAsuncion = isLocationInAsuncion(latitude, longitude);

        onCoordinatesChange(newCoordinates, isInAsuncion);
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };

  const handleInitialMarkCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newCoordinates = {
          latitude,
          longitude,
        };
        setViewport({
          ...viewport,
          latitude,
          longitude,
        });
        setMarkerPosition(newCoordinates);

        const isInAsuncion = isLocationInAsuncion(latitude, longitude);

        onCoordinatesChange(newCoordinates, isInAsuncion);
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };

  useEffect(() => {
    handleInitialMarkCurrentLocation(); // Llamar a la función para marcar la ubicación actual al cargar el componente
  }, []); // Ejecutar solo una vez al montar el componente

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoiZnNjb3NjaWEiLCJhIjoiY2xycGNxMnl0MDNvdDJqcGR6ZGowaTlvZSJ9.Js5NLO8VJHtGDkwQBqEMmw",
      mapboxgl: mapboxgl,
      marker: {
        color: "orange",
      },
      placeholder: "Buscar",
    });

    geocoder.on("result", (e) => {
      console.log(e.result.geometry);
      const center = e.result.geometry.coordinates;
      const newCoordinates = {
        longitude: center[0],
        latitude: center[1],
      };
      setMarkerPosition(newCoordinates);
      setViewport({
        ...viewport,
        longitude: center[0],
        latitude: center[1],
      });
      // Verificar si las coordenadas pertenecen a Asunción
      const { latitude, longitude } = newCoordinates;
      const isInAsuncion = isLocationInAsuncion(latitude, longitude);
      onCoordinatesChange(newCoordinates, isInAsuncion);
    });

    document.getElementById("geocoder-container").appendChild(geocoder.onAdd());
  }, []); // Solo se ejecuta una vez al montar el componente

  return (
    <div
      style={{
        width: "100%",
        flexShrink: 0,
      }}
    >
      <Map
        id="geocoder-container"
        {...viewport} // Pasar todas las propiedades de viewport como props
        onViewportChange={(newViewport) => setViewport(newViewport)} // Utilizar setViewport para actualizar el estado del viewport
        initialViewState={{
          longitude: -57.5759,
          latitude: -25.2637,
          zoom: 16,
        }}
        style={{ borderRadius: "10px", height: 400, padding: 20 }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken="pk.eyJ1IjoiZnNjb3NjaWEiLCJhIjoiY2xycGNxMnl0MDNvdDJqcGR6ZGowaTlvZSJ9.Js5NLO8VJHtGDkwQBqEMmw"
      >
        <Marker
          longitude={markerPosition.longitude}
          latitude={markerPosition.latitude}
          offsetLeft={-20}
          offsetTop={-10}
          draggable
          onDragEnd={handleMarkerDrag}
        >
          <div style={{ color: "red", fontSize: 30 }}>📍</div>
        </Marker>
        <button
          style={{
            position: "absolute",
            bottom: 12,
            right: 5,
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
          onClick={handleMarkCurrentLocation}
        >
          <img
            src={myLocation}
            alt="mi ubicacion"
            style={{ width: 20, height: 20 }}
          />
        </button>
      </Map>
    </div>
  );
}

export default MapGl;
