import "./ChefScreen.css";
import logo from "../assets/new images/logo.svg";
import Tag from "../components/Tag";
import Appbar from "../components/Appbar";
import API from "../api";
import { useEffect, useState } from "react";
import loader from "../assets/new images/buscoChefLoader.gif";

const BioScreen = (props) => {
  // Obtén los datos del chef de las props
  const { id, title, tags, imageSrc } = props.location.state;

  const [serviceProvider, setServiceProvider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const fetchtData = async () => {
    try {
      // Llama al método getServiceType() de tu clase de API
      const serviceTypesData = await API.getServiceProvider(id);
      setServiceProvider(serviceTypesData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching service types:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchtData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: windowWidth >= 1024 ? "center" : "flex-start",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <Appbar />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            paddingBottom: "40%",
            width: "100%",
          }}
        >
          <img src={loader} alt="loader" style={{ width: 182 }} />
          <p
            style={{
              fontSize: 18,
              fontWeight: "700",
              lineHeight: " 22px",
              color: "#CE2F15",
            }}
          >
            Cargando...
          </p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            flex: 1,
            gap: 25,
            width: windowWidth >= 1024 ? "50%" : "100%",
          }}
        >
          <div>
            <img
              src={imageSrc}
              alt="imgPrueba"
              style={{
                width: 167,
                height: 167,
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
          </div>
          <div style={{ fontSize: 25, fontWeight: "700", lineHeight: "33px" }}>
            {title}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: 14,
                fontWeight: "700",
                lineHeight: "16px",
                marginBottom: 25,
                gap: 5,
              }}
            >
              Tags
              <div style={{ display: "flex", gap: 5 }}>
                {tags.map((tag, index) => (
                  <Tag key={index} text={tag.name} />
                ))}
              </div>
            </div>
            <div style={{ fontSize: 16, fontWeight: "700", color: "#000000" }}>
              Sobre el chef
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: serviceProvider.long_bio }}
            />
            {/*<div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 12,
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#000000",
                }}
              >
                ¡Nos complace presentarle Tierra Colorada Gastró, un lugar donde
                la pasión por la auténtica cocina se fusiona con la creatividad
                culinaria! Con más de 6 años de experiencia, hemos sido testigos
                de la evolución gastronómica y nos enorgullece seguir sirviendo
                delicias que cautivan los paladares de nuestros clientes.
              </p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 12,
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#000000",
                }}
              >
                Nuestra Especialidad: ¡Pizzas Extraordinarias! En Tierra
                Colorada Gastró, nos consideramos maestros en el arte de la
                pizza. Nuestra masa crujiente, cuidadosamente elaborada y
                nuestro inconfundible toque creativo hacen que nuestras pizzas
                sean un auténtico deleite. Desde las clásicas Margherita hasta
                nuestras innovadoras creaciones gourmet, cada bocado es una
                experiencia única.
              </p>
              </div>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default BioScreen;
