import React from "react";
import { useState, useEffect } from "react";
import arrowleft from "../assets/images/icon-arrow-left.svg";
import arrowright from "../assets/images/icon-arrow-right.svg";
import Instruction1 from "../assets/images/instructions-1.png";
import Instruction2 from "../assets/images/instructions-2.png";
import Instruction3 from "../assets/images/instructions-3.png";
import Instruction4 from "../assets/images/instructions-4.png";
import Instruction5 from "../assets/images/instructions-5.png";

const Help = (props) => {
  const [instruction, setinstruction] = useState(0);
  const [activeModal, setactiveModal] = useState(true);
  useEffect(() => {}, [instruction, activeModal]);
  const instructions = [
    <p>
      Paso 1: Seleccioná la fecha, la hora y la cantidad de personas para tu
      evento. Luego de haber seleccionado todas esas opciones, escogé uno de
      nuestros chefs o proveedores de catering
    </p>,
    <p>
      Paso 2: Seleccioná el menu que mas te guste, podes visualizar la bio del
      chef para ver mas info sobre el mismo
    </p>,
    <p>
      Paso 3: Una vez seleccionado el menú, te pediremos tus datos básicos para
      almacenar tu pedido, favor verificar si los datos están correctos antes de
      enviarlos
    </p>,
    <p>
      Paso 4: Podés agregar observaciones para el chef antes de finalizar tu
      orden, una vez finalizado. Confirmá tu orden.
    </p>,
    <p>
      Paso 5: Te llegará un correo de confirmación una vez recepcionado el
      pedido ¡Gracias por usar la app!
    </p>,
  ];
  const images = [
    Instruction1,
    Instruction2,
    Instruction3,
    Instruction4,
    Instruction5,
  ];
  const handleHelp = (e) => {
    if (e === "left") {
      if (instruction === 0) {
        setinstruction(4);
      } else {
        let a = instruction - 1;
        setinstruction(a);
      }
    } else if (e === "right") {
      if (instruction === 4) {
        setinstruction(0);
      } else {
        let a = instruction + 1;
        setinstruction(a);
      }
    }
  };

  return (
    <div
      className={`modal ${activeModal ? "is-active" : ""}`}
      style={{ paddingRight: "5%", paddingLeft: "5%" }}
    >
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">¿Cómo usar?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => setactiveModal(false)}
          ></button>
        </header>
        <section className="modal-card-body is-flex is-align-items-center is-justify-content-center ">
          <img src={images[instruction]} alt="inst1" style={{ width: "60%" }} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-column ">
          {instructions[instruction]}
          <div className="is-flex is-justify-content-space-around pt-3">
            <img
              src={arrowleft}
              alt="al"
              style={{ paddingRight: "10px" }}
              onClick={() => handleHelp("left")}
            />
            <img
              src={arrowright}
              alt="ar"
              onClick={() => handleHelp("right")}
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Help;
