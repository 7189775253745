import React from 'react';
import Construction from '../components/Construction';
import { useHistory } from 'react-router';
import Appbar from '../components/Appbar';
const Policy = () => {
    let history = useHistory();
    return (
        <div className="white-bg">
            <Appbar />
            <div
                className="title white-bg is-flex is-align-items-center "
                style={{
                    height: '78px',
                    fontSize: '25px',
                    paddingLeft: `4vw`,
                    paddingRight: `4vw`,
                }}
            >
                Políticas de Privacidad
            </div>
            <div
                className="white-bg"
                style={{ paddingLeft: `4vw`, paddingRight: `4vw` }}
            >
                <h3>
                    <strong>Comensales</strong>
                </h3>
                <p>
                    Como Comensal, siempre y cuando cumplas las condiciones
                    establecidas en estas Pol&iacute;ticas , podr&aacute;s
                    solicitar un Servicio a trav&eacute;s de la Plataforma,
                    introduciendo la informaci&oacute;n relativa al Servicio que
                    requieres (fecha, ubicaci&oacute;n, tipo de cocina, etc.) y
                    Registrarte como Usuario cargando el formulario. <br />
                    Al registrar tu Solicitud, recibir&aacute;s una
                    notificaci&oacute;n por email con tus datos de contacto como
                    Usuario y mensaje a trav&eacute;s de whatsapp. Una vez
                    registrado el equipo de busco chef se pondr&aacute; en
                    contacto para ultimar detalles de la reserva.
                </p>
                <br />
                <h3>
                    <strong>Chefs</strong>
                </h3>
                <p>
                    Como Chef o empresa, siempre y cuando cumplas las
                    condiciones establecidas a continuaci&oacute;n,
                    podr&aacute;s ofrecer servicios de chef privado a los
                    Comensales que registren una solicitud.
                </p>
                <p>
                    Solamente podr&aacute;s responder a una Solicitud con tu
                    Oferta si cumples todas y cada una de las condiciones
                    indicadas a continuaci&oacute;n:
                </p>
                <br />
                <p>-Eres mayor de edad.</p>
                <p>
                    -Cumples los requisitos legales exigidos por la
                    legislaci&oacute;n local vigente en materia de trabajo, bien
                    sea como sociedad mercantil o trabajador aut&oacute;nomo.
                </p>
                <p>
                    -El chef o empresa de catering es responsable contra todo
                    da&ntilde;o que pueda producirse en la prestaci&oacute;n de
                    servicios de chef privado, tanto respecto a eventuales
                    intoxicaciones alimentarias, como a da&ntilde;os a bienes
                    muebles e inmuebles utilizados durante la prestaci&oacute;n
                    de servicios, as&iacute; como a da&ntilde;os a bienes
                    inmuebles que se encuentren en el domicilio del Comensal y
                    que pudieran sufrir un perjuicio durante el tiempo en el que
                    te encuentres ejecutando el Servicio.
                </p>
                <p>
                    -Cumples con la legislaci&oacute;n aplicable en tu
                    territorio referente al transporte de materias primas,
                    cocinadas o no, garantizando las condiciones de higiene y
                    seguridad alimentaria exigidas por la autoridad
                    correspondiente dentro de la ubicaci&oacute;n
                    geogr&aacute;fico donde se presta el Servicio.
                </p>
                <p>
                    -Utilizas utensilios en buen estado y tomas las medidas
                    necesarias para garantizar la correcta higiene de los
                    mismos.
                </p>
                <p>
                    -Garantizas el buen estado de las materias primas utilizadas
                    durante la prestaci&oacute;n de servicios.
                </p>
                <br />
                <p>
                    Reconoces que t&uacute; eres el &uacute;nico responsable del
                    contenido de la Oferta que se exhibe a trav&eacute;s de la
                    Plataforma. Por lo tanto, reconoces y garantizas la
                    veracidad y precisi&oacute;n de toda la informaci&oacute;n
                    incluida en tu Men&uacute; y Mensajes, y te comprometes a
                    realizar el Servicio bajo las condiciones descritas en tu
                    Oferta.
                    <br />
                    Tu Oferta, que deber&aacute; cumplir con las presentes
                    Pol&iacute;ticas, ser&aacute; Expuesta al Comensal en
                    respuesta a su Solicitud. Busco Chef se reserva el derecho,
                    a su exclusivo criterio y sin necesidad de previo aviso, a
                    no enviar o a borrar, en cualquier momento, cualquier
                    elemento de la Oferta que no cumpla con los presentes
                    Pol&iacute;ticas o que considere que puede da&ntilde;ar su
                    imagen o la imagen de la Plataforma.
                </p>
                <h3>
                    <br />
                    <strong>Reservar un Servicio</strong>
                </h3>
                <p>
                    Procedimiento de reserva de un servicio
                    <br />
                    Busco Chef ha establecido un sistema para la reserva de
                    servicios en l&iacute;nea a trav&eacute;s de la Plataforma.
                    <br />
                    Para poder reservar un servicio a trav&eacute;s de Busco
                    Chef es necesario que el Comensal registre una Solicitud en
                    la Plataforma, que ser&aacute; derivada
                    autom&aacute;ticamente a los Chefs disponibles en la fecha
                    fijada por el Comensal, en respuesta a dicha Solicitud y que
                    el Comensal pague a trav&eacute;s de la Plataforma el Precio
                    fijado por el Chef o empresa de catering. <br />
                    Una vez realizada la Reserva, y de forma autom&aacute;tica,
                    ambos usuarios (Comensal y Chef) recibir&aacute;n una
                    notificaci&oacute;n de correo electr&oacute;nico confirmando
                    la Reserva y detallando los datos de contacto de la otra
                    parte, posteriormente el equipo de busco chef se
                    pondr&aacute; en contacto con ambas partes para reafirmar
                    detalles del evento.
                </p>
                <br />
                <h3>Sistema de evaluaci&oacute;n</h3>
                <p>
                    <br />
                    <strong>Funcionamiento</strong>
                </p>
                <p>
                    Busco Chef te anima como Comensal a publicar tus
                    valoraciones sobre un Chef tras disfrutar de un Servicio
                    contratado a trav&eacute;s de la Plataforma.
                </p>
                <p>
                    <br />
                    <strong>Moderaci&oacute;n</strong>
                </p>
                <p>
                    Reconoces y aceptas que Busco Chef se reserva el derecho a
                    no publicar o a eliminar cualquier valoraci&oacute;n
                    considera que su contenido incumple lo establecido en las
                    presentes Pol&iacute;ticas
                </p>
                <p>
                    <br />
                    <strong>L&iacute;mite</strong>
                </p>
                <p>
                    Busco Chef se reserva el derecho a suspender tu Cuenta de
                    Chef, limitar su acceso a la Plataforma, o rescindir los
                    presentes t&eacute;rminos en caso de que hayas recibido al
                    menos tres cr&iacute;ticas y las opiniones promedias que
                    hayas recibido tengan una puntuaci&oacute;n de 3 o inferior.
                </p>
                <p>
                    <br />
                    <strong>Condiciones financieras</strong>
                </p>
                <p>
                    El acceso y el registro en la Plataforma, as&iacute; como el
                    env&iacute;o de Solicitudes y Ofertas, no implican coste
                    alguno. No obstante, la Reserva se confirmar&aacute; tras el
                    pago correspondiente de un Precio, bajo las condiciones
                    descritas a continuaci&oacute;n.
                </p>
                <br />
                <h3>Precio</h3>
                <p>
                    <br />
                    <strong>Condiciones espec&iacute;ficas para el Chef</strong>
                </p>
                <p>
                    Al enviar una Oferta a trav&eacute;s de la Plataforma debes
                    proporcionar informaci&oacute;n completa y exacta acerca de
                    tu Servicio (como los servicios adicionales incluidos, el
                    precio para cada rango de comensales, etc.). Eres
                    responsable de mantener actualizada en todo momento la
                    informaci&oacute;n de tu Oferta, y cancelarla si dejas de
                    estar disponible para la fecha indicada.
                    <br />
                    Eres el responsable en exclusiva de fijar un Precio
                    (incluidos Impuestos, si procede) en tu Oferta. A la hora de
                    fijar el Precio, y antes de enviar tu Oferta, la Plataforma
                    te indicar&aacute; siempre el porcentaje correspondiente a
                    Gastos de Gesti&oacute;n. De esta forma, puedes establecer
                    el Precio que estimes oportuno, teniendo en cuenta 15% de
                    comisi&oacute;n de busco chef que corresponde a Gastos de
                    Gesti&oacute;n. Una vez que un Comensal reserva tu Oferta,
                    no podr&aacute;s exigir que el Comensal pague un Precio
                    m&aacute;s elevado que el fijado en tu Oferta.
                    <br />
                    Las im&aacute;genes utilizadas en tu Perfil de Chef deben
                    reflejar con exactitud la calidad y la condici&oacute;n de
                    tus Servicios. Busco Chef se reserva el derecho a exigir que
                    tu Perfil de Chef tenga un n&uacute;mero m&iacute;nimo de
                    Im&aacute;genes de un determinado formato, tama&ntilde;o y
                    resoluci&oacute;n.
                    <br />
                    La asignaci&oacute;n de Solicitudes dentro de la Plataforma
                    puede variar y depende de diversos factores, como las
                    preferencias del Comensal, el Precio y la disponibilidad de
                    fechas, el n&uacute;mero y calidad de las Im&aacute;genes,
                    los Comentarios y Evaluaciones, el tipo de Servicio de Chef
                    y/o la facilidad para reservar.
                </p>
                <p>
                    <br />
                    <strong>
                        Condiciones espec&iacute;ficas para el Comensal
                    </strong>
                </p>
                <p>
                    Como Comensal, puedes reservar un Servicio a trav&eacute;s
                    de la Plataforma siguiendo el proceso de reserva
                    correspondiente. Tras la recepci&oacute;n de una
                    confirmaci&oacute;n de Reserva por email de parte de Busco
                    Chef se forma un contrato legalmente vinculante entre
                    Comensal y Chef, sujeto a cualesquiera t&eacute;rminos y
                    condiciones acordados entre las partes. Busco Chef
                    cobrar&aacute; el Precio acordado por las partes en el
                    momento de la Reserva.
                    <br />
                    Si reservas un Servicio en nombre de varias personas y una
                    de ellas es menor de edad, declaras y garantizas estar
                    legalmente autorizado para actuar en nombre del menor. Los
                    menores solo pueden participar en un Servicio si
                    est&aacute;n acompa&ntilde;ados por un adulto que se haga
                    responsable de ellos.
                </p>
                <p>
                    <br />
                    <strong>Comisi&oacute;n de servicio</strong>
                </p>
                <p>
                    Busco Chef cobrar&aacute; por el uso de la Plataforma una
                    Comisi&oacute;n de 15%, calcul&aacute;ndose siempre como un
                    porcentaje sobre la base del precio fijado por el Chef en su
                    Oferta. Dicho porcentaje ser&aacute; siempre visible para el
                    Chef a la hora de establecer el Precio de su Oferta, y
                    previamente al env&iacute;o de la misma. La comisi&oacute;n
                    se cobrar&aacute;n en el momento de confirmar la Reserva.
                </p>
                <p>
                    <br />
                    <strong>
                        M&eacute;todos de pago y repago de la Cantidad de costes
                        compartidos al Conductor
                    </strong>
                </p>
                <p>
                    Al utilizar la Plataforma como Chef, concedes a Busco Chef
                    una autorizaci&oacute;n de cobro de la cantidad
                    correspondiente a los Gastos de Gesti&oacute;n en tu nombre
                    y a tu nombre.
                    <br />
                    Por consiguiente, tras la Reserva del Comensal, Busco Chef
                    cobra la cantidad total pagada por el Comensal. La cantidad
                    correspondiente a la Tarifa del Chef recibida por Busco Chef
                    se deposita en una cuenta dedicada al pago de los Chefs.
                    <br />
                    Reconoces y aceptas que ninguna de las cantidades
                    mencionadas recibidas por Busco Chef en nombre del Chef dan
                    derecho al cobro de intereses. Te comprometes a responder
                    con debida diligencia a cualquier solicitud de Busco Chef, y
                    en general, a cualquier autoridad administrativa o judicial
                    competente, especialmente en lo relacionado con la
                    prevenci&oacute;n del blanqueo de capitales.
                    Fundamentalmente aceptas proporcionar, tras su solicitud,
                    cualquier elemento de prueba de tu direcci&oacute;n y/o
                    identidad que sea de utilidad.
                </p>
                <p>
                    En caso de falta de respuesta a dichas solicitudes, Busco
                    Chef podr&aacute; tomar las medidas que considere
                    necesarias, como por ejemplo congelar las cantidades pagadas
                    y/o suspender tu Cuenta o resolver las presentes.
                </p>
                <p>
                    <br />
                    <strong>
                        Pago de la Tarifa del Chef o empresa de catering
                    </strong>
                </p>
                <p>
                    Tras el Servicio, los Usuarios tendr&aacute;n un
                    per&iacute;odo de 24 horas desde el momento en el que el
                    Servicio haya finalizado para presentar una
                    reclamaci&oacute;n a busco Chef sobre el mismo. En ausencia
                    de reclamaci&oacute;n de los Usuarios durante este
                    per&iacute;odo, Busco Chef deber&aacute; considerar el
                    Servicio confirmado.
                    <br />A partir del momento de esta confirmaci&oacute;n
                    expresa o t&aacute;cita, tendr&aacute;s, como Chef o
                    empresa, un cr&eacute;dito a ser abonado en tu Cuenta. Este
                    cr&eacute;dito se corresponde a la cantidad total pagada por
                    el Comensal en el momento de confirmar la Reserva tras
                    haberle restado la comisi&oacute;n.
                    <br />
                    La orden de pago a tu nombre se enviar&aacute; el primer
                    d&iacute;a laboral tras la ejecuci&oacute;n del Servicio
                    (siempre y cuando busco Chef tenga tu informaci&oacute;n
                    bancaria).
                    <br />
                    Una vez finalizados los per&iacute;odos de tiempo
                    l&iacute;mite aplicables, cualquier cantidad que no haya
                    sido reclamada ser&aacute; considerada como propia de busco
                    Chef.
                </p>
                <p>
                    <br />
                    <strong>Pol&iacute;tica de cancelaci&oacute;n</strong>
                </p>
                <p>
                    Condiciones de reembolso del pago en caso de
                    cancelaci&oacute;n
                    <br />
                    La cancelaci&oacute;n de un Servicio estar&aacute; sujeta a
                    lo siguiente:
                    <br />
                    En caso de que la cancelaci&oacute;n la realice el Chef, al
                    Comensal se le devolver&aacute; el importe total que haya
                    pagado.
                </p>
                <p>En caso de cancelaci&oacute;n por parte del Comensal:</p>
                <ul>
                    <li>
                        - Si el Comensal cancela el Servicio con una
                        antelaci&oacute;n superior a 30 d&iacute;as
                    </li>
                    <li>
                        - Si el Comensal cancela el Servicio con una
                        antelaci&oacute;n superior a 7 d&iacute;as e inferior a
                        30 d&iacute;as antes de la hora fijada para el Servicio,
                        el Comensal tendr&aacute; derecho al reembolso del 50%
                        del importe abonado, que ser&aacute; abonado al Chef
                    </li>
                    <li>
                        - Si el Comensal cancela el Servicio con una
                        antelaci&oacute;n inferior a 4 d&iacute;as antes de la
                        hora fijada para el Servicio, no tendr&aacute; lugar
                        devoluci&oacute;n alguna al Comensal. El Chef
                        recibir&aacute; la Tarifa de Servicio fijada en su
                        Oferta, y busco Chef se quedar&aacute; los Gastos
                        administrativos.
                    </li>
                </ul>
                <p>
                    Busco chef valorar&aacute;, a su criterio y seg&uacute;n la
                    informaci&oacute;n disponible, la legitimidad de las
                    solicitudes de reembolso.
                    <br />
                    En caso de que un reembolso aplique, busco chef
                    retendr&aacute; el 7% del total del servicio que cubren las
                    tasas transaccionales no reembolsables.
                </p>
                <p>
                    <br />
                    <strong>Plazos de reclamaci&oacute;n</strong>
                </p>
                <p>
                    En caso de que el Comensal desee realizar una
                    reclamaci&oacute;n relativa a un Servicio contratado,
                    deber&aacute; contactar a Busco Chef dentro de las 48 horas
                    siguientes a la fecha y hora previstas para la
                    ejecuci&oacute;n del Servicio.
                    <br />
                    Una vez pasado dicho plazo, si el objeto de la
                    reclamaci&oacute;n del Comensal fuera una solicitud de
                    reembolso del importe pagado en la contrataci&oacute;n del
                    Servicio por cualesquiera motivos o defectos en su
                    ejecuci&oacute;n, aun cuando dichos motivos o defectos no
                    sean responsabilidad del Comensal, y siempre y cuando el
                    Servicio ya hubiera sido abonado al Chef, la
                    reclamaci&oacute;n ser&aacute; denegada.
                </p>
                <p>
                    <br />
                    <strong>Datos de car&aacute;cter personal</strong>
                </p>
                <p>
                    En el contexto del uso de la Plataforma por tu parte, Busco
                    Chef recopilar&aacute; y procesar&aacute; tu
                    informaci&oacute;n personal. Al utilizar la Plataforma y
                    registrarte como Usuario, reconoces y aceptas el
                    procesamiento de tus datos personales por parte de Busco
                    Chef de conformidad con la legislaci&oacute;n aplicable y lo
                    establecido en la Pol&iacute;tica de privacidad.
                </p>
                <p>
                    <br />
                    <strong>
                        Funcionamiento, disponibilidad y funcionalidades de la
                        Plataforma
                    </strong>
                </p>
                <p>
                    Busco chef intentar&aacute; en la medida de lo posible
                    mantener el funcionamiento constante de la Plataforma los 7
                    d&iacute;as de la semana y las 24 horas del d&iacute;a. Sin
                    embargo, es posible que se pueda suspender de forma temporal
                    el acceso a la Plataforma, sin previo aviso, por motivos
                    t&eacute;cnicos, de mantenimiento, migraci&oacute;n u
                    operaciones de actualizaci&oacute;n, o debido a cortes de
                    suministro o restricciones relacionadas con el
                    funcionamiento de la red.
                </p>
                <p>
                    Adem&aacute;s, Busco Chef se reserva el derecho a modificar
                    o suspender en su totalidad o en parte el acceso a la
                    Plataforma o sus funcionalidades, a su discreci&oacute;n,
                    bien sea de forma temporal o permanente.
                </p>
                <p>
                    <br />
                    <strong>Funci&oacute;n de Busco Chef</strong>
                </p>
                <p>
                    La Plataforma constituye una plataforma de conexi&oacute;n
                    de red en l&iacute;nea en la cual los Usuarios pueden tanto
                    solicitar como ofrecer servicios de chef privado mediante
                    Solicitudes y Ofertas correspondientemente.
                    <br />
                    Al utilizar la Plataforma y aceptar las presentes
                    Pol&iacute;ticas, reconoces que Busco Chef no es parte de
                    ning&uacute;n acuerdo formalizado entre t&uacute; y los
                    dem&aacute;s Usuarios.
                    <br />
                    Busco Chef no tiene ning&uacute;n tipo de control sobre el
                    comportamiento de sus Usuarios y los usuarios de la
                    Plataforma. No explota, suministra ni administra ninguno de
                    los servicios incluidos en las Ofertas, y no ofrece
                    Servicios en la Plataforma.
                    <br />
                    Reconoces y aceptas que Busco Chef no controla la validez,
                    veracidad o legalidad de las Ofertas enviadas / recibidas.
                    En su capacidad de intermediario, Busco Chef no ofrece
                    servicio gastron&oacute;mico alguno ni act&uacute;a en
                    calidad de suministrador. La funci&oacute;n de Busco Chef se
                    limita a facilitar el acceso a la Plataforma.
                    <br />
                    Los Usuarios (Chefs ,empresas o Comensales) act&uacute;an
                    bajo su &uacute;nica y total responsabilidad.
                    <br />
                    En su capacidad de intermediario, Busco Chef no ser&aacute;
                    considerado responsable de ning&uacute;n incidente que tenga
                    lugar durante un Servicio, en especial en lo relacionado
                    con:
                </p>
                <ul>
                    <li>
                        - Informaci&oacute;n err&oacute;nea comunicada por el
                        Chef en su Oferta, o por cualquier otro medio, con
                        respecto al Servicio y sus condiciones
                    </li>
                    <li>
                        - La cancelaci&oacute;n o modificaci&oacute;n de un
                        Servicio por parte de un Usuario;
                        <br />
                        El comportamiento de sus Usuarios durante, antes o
                        despu&eacute;s del Servicio.
                    </li>
                </ul>
                <p>
                    <br />
                    <strong>
                        Contenido publicado por el Usuario en la Plataforma
                    </strong>
                </p>
                <p>
                    Con el objetivo de permitir la provisi&oacute;n de los
                    Servicios, y en cumplimiento con el objetivo de la
                    Plataforma, le otorgas a Busco Chef una licencia no
                    exclusiva para usar el contenido y los datos que has
                    proporcionado en el contexto de la utilizaci&oacute;n de la
                    Plataforma (de aqu&iacute; en adelante denominados como el
                    &ldquo;Contenido desarrollado por el Usuario&rdquo;). Para
                    poder permitir a Busco Chef distribuir por medio de la red
                    digital y de conformidad con cualquier protocolo de
                    comunicaci&oacute;n (en especial Internet y las redes
                    m&oacute;viles) y para poder ofrecer al p&uacute;blico el
                    contenido de la Plataforma, autorizas a Busco Chef a
                    reproducir, representar, adaptar y traducir tu Contenido
                    desarrollado por el Usuario para todo el mundo y durante la
                    duraci&oacute;n completa de tus relaciones contractuales con
                    Busco Chef, de conformidad con lo siguiente:
                </p>
                <ul>
                    <li>
                        - Autorizas a Busco Chef a reproducir todo o parte de tu
                        Contenido desarrollado por el Usuario en cualquier medio
                        de grabaci&oacute;n digital, conocido o por conocer, en
                        concreto en cualquier servidor, disco duro, tarjeta de
                        memoria u otro medio equivalente, en cualquier formato y
                        por medio de cualquier proceso, ya conocido o por
                        conocer, en el grado necesario para cualquier
                        operaci&oacute;n de almacenamiento, copia de seguridad,
                        transmisi&oacute;n o descarga relacionada con el
                        funcionamiento de la Plataforma y el suministro del
                        Servicio
                    </li>
                    <li>
                        - Autorizas a Busco Chef a adaptar y traducir su
                        Contenido desarrollado por el Usuario, y a reproducir
                        dichas adaptaciones en cualquier medio digital, conocido
                        o por conocer, establecido, con el objetivo de
                        suministrar los Servicios. Este derecho incluye la
                        opci&oacute;n de realizar modificaciones en el formato
                        de tu Contenido desarrollado por el Usuario, con
                        respecto a tu derecho moral, con el objetivo de respetar
                        el dise&ntilde;o gr&aacute;fico de la Plataforma o para
                        hacer que tu Contenido sea compatible a nivel
                        t&eacute;cnico con nuestro software para su posterior
                        publicaci&oacute;n por medio de la Plataforma.
                    </li>
                </ul>
                <p>
                    <br />
                    <strong>
                        Comportamiento de las personas que visitan&nbsp;la
                        Plataforma y los Usuarios
                    </strong>
                </p>
                <p>
                    Compromiso de todas las personas que visitan la Plataforma
                    <br />
                    Reconoces ser el &uacute;nico responsable de respetar todas
                    las leyes, normativas y obligaciones aplicables a la
                    utilizaci&oacute;n de la Plataforma.
                </p>
                <ul>
                    <li>
                        - No enviar a Busco Chef (especialmente en el momento de
                        crear o actualizar tu perfil) ni a los dem&aacute;s
                        Usuarios de la Plataforma informaci&oacute;n falsa,
                        confusa, maliciosa o fraudulenta;
                    </li>
                    <li>
                        - No hablar ni comportarse , ni publicar ning&uacute;n
                        contenido en la Plataforma que pueda resultar
                        difamatorio, injurioso, obsceno, pornogr&aacute;fico,
                        vulgar, ofensivo, agresivo, improcedente, violento,
                        amenazante, acosador, de naturaleza racista o
                        xen&oacute;foba, que tenga connotaciones sexuales,
                        incite a la violencia, discriminaci&oacute;n u odio, o
                        que fomente actividades o el uso de substancias ilegales
                        o de forma m&aacute;s general que sea contrario a los
                        objetivos de la Plataforma y que pueda infringir los
                        derechos de Busco Chef o una tercera parte, o que pueda
                        ser contrario a las buenas costumbres;
                    </li>
                    <li>
                        - No infringir los derechos y no da&ntilde;ar la imagen
                        de Busco Chef, en especial en lo que respecta a sus
                        derechos de propiedad intelectual;
                        <br />
                        No abrir m&aacute;s de una Cuenta en la Plataforma y no
                        abrir una Cuenta en nombre de un tercero;
                    </li>
                    <li>
                        - No intentar eludir el sistema de reserva en
                        l&iacute;nea de la Plataforma, principalmente intentando
                        enviar a otro Usuario tu informaci&oacute;n de contacto
                        para realizar la reserva fuera de la Plataforma con el
                        objetivo de evitar el pago de Comisi&oacute;n ;
                    </li>
                    <li>
                        - No ponerse en contacto con otro Usuario, por medio de
                        la Plataforma, con otra finalidad que no sea la
                        definici&oacute;n de las condiciones del Servicio de
                        chef privado;
                    </li>
                    <li>
                        - No aceptar ni realizar el pago fuera de la Plataforma;
                    </li>
                    <li>
                        - Cumplir con las presentes normas&nbsp;y con la
                        Pol&iacute;tica de privacidad.
                    </li>
                </ul>
                <p>
                    <br />
                    <strong>Compromisos de los Chefs</strong>
                </p>
                <p>
                    Cuando utilizas la Plataforma como Chef o empresa, te
                    comprometes a:
                </p>
                <ul>
                    <li>
                        - Respetar todas las leyes, normativas y c&oacute;digos
                        con respecto a la actividad de chef privado,
                        especialmente contar con un seguro de responsabilidad
                        civil en vigor en el momento de realizar el Servicio
                    </li>
                    <li>
                        - No asumir ning&uacute;n riesgo durante la
                        ejecuci&oacute;n del Servicio, ni tomar ning&uacute;n
                        producto o sustancia que pueda afectar negativamente a
                        tu atenci&oacute;n y a la debida diligencia que requiere
                        la actividad propia del Servicio
                    </li>
                    <li>
                        - Ejecutar el Servicio seg&uacute;n la
                        descripci&oacute;n de la Oferta y a respetar las horas y
                        lugares acordados con el Comensal
                    </li>
                    <li>
                        - Utilizar utensilios en buen estado, cumpliendo con
                        todas las normativas legales en la materia,
                        especialmente aquellas relativas a la higiene
                    </li>
                    <li>
                        - Utilizar materias primas en perfecto estado, que
                        cumplan con los requisitos sanitarios exigidos por la
                        legislaci&oacute;n vigente aplicable a la materia
                    </li>
                    <li>
                        - Cumplir con las normativas legales en materia de
                        transporte de alimentos, y en especial aquellas
                        referentes al respeto de la cadena de fr&iacute;o
                    </li>
                    <li>
                        - No tener ninguna contraindicaci&oacute;n ni
                        incapacidad m&eacute;dica para desarrollar la actividad
                        propia del Servicio
                    </li>
                    <li>
                        - Comportarse de forma apropiada y responsable durante
                        el Servicio, de conformidad con estas Pol&iacute;ticas{' '}
                    </li>
                </ul>
                <p>
                    <br />
                    <strong>Propiedad intelectual</strong>
                </p>
                <p>
                    <em>10.1. Contenido publicado por Busco chef</em> <br />
                    Con sujeci&oacute;n al contenido proporcionado por sus
                    Usuarios, Busco Chef es el titular &uacute;nico de todos los
                    derechos de propiedad intelectual relacionados con el
                    Servicio, la Plataforma, su contenido (en concreto los
                    textos, im&aacute;genes, dise&ntilde;os, logotipos,
                    v&iacute;deos, sonido, datos y gr&aacute;ficos) y con el
                    software y las bases de datos que aseguran su
                    funcionamiento.
                </p>
                <p>
                    Busco Chef te garantiza un derecho personal, no exclusivo y
                    no transferible para utilizar la Plataforma, para tu uso
                    personal y privado, y de conformidad con la finalidad de la
                    Plataforma y las presentes Pol&iacute;ticas.
                </p>
                <p>
                    <br />
                    Est&aacute; totalmente prohibido utilizar o explotar la
                    Plataforma, incluido su contenido, con cualquier otra
                    finalidad que no sea la prevista sin previo consentimiento
                    por escrito de Busco Chef.
                </p>
                <p>
                    En concreto, est&aacute; totalmente prohibido lo siguiente:
                </p>
                <ul>
                    <li>
                        - Reproducir, modificar, adaptar, distribuir,
                        representar p&uacute;blicamente y diseminar la
                        Plataforma y todo su contenido, salvo previa
                        autorizaci&oacute;n expresa de Busco Chef
                    </li>
                    <li>
                        - Descompilar y aplicar ingenier&iacute;a inversa a la
                        Plataforma, salvo las excepciones estipuladas por los
                        textos aplicables
                    </li>
                    <li>
                        - Extraer o intentar extraer (en concreto utilizando
                        robots de extracci&oacute;n de datos o herramientas
                        similares de recolecci&oacute;n de datos) una parte
                        sustancial de los datos de la Plataforma.
                    </li>
                    <br />
                    <br />
                </ul>
            </div>
        </div>
    );
};

export default Policy;
