import React from 'react';
import chefIcon from '../assets/images/chef-icon.png';
import { useHistory } from 'react-router-dom';
const ChefCard = (props) => {
    const {
        chef,
        date,
        people_qty,
        hour,
        history,
        hasSelected,
        setIsPeopleNotSelected,
    } = props;
    const goToSelectMenu = () => {
        if (people_qty === null) {
            setIsPeopleNotSelected(false);
        } else {
            history.push(
                `/select-menu?people_qty=${
                    people_qty === null ? 20 : people_qty
                }&date=${date}&chef_id=${chef.id}&hour=${hour}`
            );
        }
    };
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return (
        <div
            className={`is-flex is-align-items-center is-justify-content-space-around card-component my-1 zoom`}
            onClick={() => goToSelectMenu()}
        >
            <img
                src={chef.profile_picture ? chef.profile_picture : chefIcon}
                className="chef-icon ml-3"
                alt="Chef"
            />
            <div
                className="px-3 is-flex is-flex-direction-column is-justify-content-center"
                style={{ width: '60%' }}
            >
                <h2 className="medium-text black-text">{chef.name}</h2>
                <p className="baseXS-text">{chef.short_bio}</p>
            </div>
            <div
                className="is-flex is-flex-direction-column is-justify-content-center mr-1"
                style={{ width: '20%' }}
            >
                <h2 className="baseM-text">Desde</h2>
                <p className="baseXS-black-text">
                    Gs. {`${numberWithCommas(parseInt(chef.starting_price))}`}
                </p>
            </div>
        </div>
    );
};

export default ChefCard;
