import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DayCircle from "../components/DayCircle";
import calendar from "../assets/new images/calendar.svg";
import MenuCard from "../components/MenuCard";
import UsersIcon from "../assets/new icons/UsersIcon";
import Appbar from "../components/Appbar";
import API from "../api";
import ClockIcon from "../assets/new icons/ClockIcon";
import MapPinIcon from "../assets/new icons/MapPinIcon";
import loader from "../assets/new images/buscoChefLoader.gif";

const ChefDetailsScreen = (props) => {
  const {
    id,
    title,
    isNew,
    imageSrc,
    peopleCount,
    daysAvailable,
    services,
    tags,
    price,
  } = props.location.state;

  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState([]);
  const [data, setData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        // Llama al método getServiceType() de tu clase de API
        const serviceProviderData = await API.getServiceProvider(id);
        setData(serviceProviderData);
        // Actualiza el estado con los menus del servicio obtenido
        setMenus(serviceProviderData.menus);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching service types:", error);
        setLoading(false);
      }
    };

    fetchServiceProvider();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <Appbar />
      </div>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              paddingBottom: "40%",
            }}
          >
            <img src={loader} alt="loader" style={{ width: 182 }} />
            <p
              style={{
                fontSize: 18,
                fontWeight: "700",
                lineHeight: " 22px",
                color: "#CE2F15",
              }}
            >
              Cargando...
            </p>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: windowWidth >= 1024 ? "center" : "flex-start",
                padding: 20,
                gap: 7,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <img
                  src={imageSrc}
                  alt={title}
                  style={{
                    height: 125,
                    width: 125,
                    borderRadius: "8px",
                    objectFit: "cover",
                    border: "2.119px solid #EDEDED",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <h2
                    style={{
                      color: "#000",
                      padding: 0,
                      margin: 0,
                      fontSize: 18,
                      fontWeight: "700",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    {title}
                  </h2>
                  <Link
                    to={{ pathname: "/bio", state: props.location.state }}
                    style={{
                      color: "#000",
                      fontSize: 12,
                      fontWeight: "600",
                      lineHeight: "16px",
                    }}
                  >
                    Ver Biografia
                  </Link>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      width: "100%",
                      borderRadius: "9px",
                      marginTop: 15,
                    }}
                  >
                    <div
                      className="people-count"
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        color: "#333333",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#E38B7C",
                          borderRadius: "50%",
                          width: 15,
                          height: 15,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <UsersIcon size={12} color="#FFF" />
                      </div>
                      Desde {data.min_diners} personas
                    </div>
                    {/*<div className="days">
            <img
              src={calendar}
              alt="calendar"
              style={{ width: 16, height: 16 }}
            />
            {daysAvailable.map((day, index) => (
              <DayCircle key={index} day={day.day} enabled={day.enabled} />
            ))}
            </div>*/}
                    <span
                      className="services"
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        color: "#333333",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#E38B7C",
                          borderRadius: "50%",
                          width: 15,
                          height: 15,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ClockIcon size={12} color="#FFF" />
                      </div>

                      {services}
                    </span>
                    <span
                      className="services"
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        color: "#333333",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#E38B7C",
                          borderRadius: "50%",
                          width: 15,
                          height: 15,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MapPinIcon size={12} color="#FFF" />
                      </div>
                      Sin costo adicional en Asunción
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: windowWidth >= 1024 ? "50%" : "100%",
                height: 6,
                flexShrink: 0,
                backgroundColor: "#F7F4F8",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: windowWidth >= 1024 ? "center" : "flex-start",
                padding: 20,
                gap: 7,
              }}
            >
              <h2
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#000",
                  fontSize: 25,
                  fontWeight: "700",
                  fontFamily: "Nunito Sans",
                }}
              >
                Menús disponibles
              </h2>
              {/* Puedes usar props.tags para pasar las etiquetas a MenuCard */}
              {menus.map((m) => (
                <MenuCard menuData={m} data={data} key={m.id} showDes={true} />
              ))}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ChefDetailsScreen;
