import ChevronLeft from "../assets/new icons/ChevronLeft";
import logo from "../assets/new images/logo.svg";

const CustomAppBar = ({ onClick }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          width: 37,
          height: 37,
          backgroundColor: "#f0f0f0",
          position: "absolute",
        }}
        onClick={onClick}
      >
        <ChevronLeft size={17} />
      </div>
      <img src={logo} alt="Logo" className="logo" />
      <div className="column">
        {/*La columna esta vacia, nos ayuda a crear un espacio 
                para que el navBar se vea como de dos elementos justificados 
                a la izquierda, pero en realidad son 3 elementos en flex 
                con una columna vacia*/}
      </div>
    </div>
  );
};

export default CustomAppBar;
