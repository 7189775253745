import defaultProps from "./defaultProps";

export default function ChevronLeft({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4584 8.5H3.54169"
        stroke={color}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50002 13.4583L3.54169 8.49996L8.50002 3.54163"
        stroke={color}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChevronLeft.defaultProps = defaultProps;
