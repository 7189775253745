const Tag = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "5px",
        justifycontent: "center",
        alignItems: "center",
        borderRadius: 7,
        background: "#424242",
        color: "var(--4, #FFF)",
        fontSize: 8,
        fontWeight: "400",
        lineHeight: "initial",
        height: 22,
        maxWidth: 85,
      }}
    >
      <p
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default Tag;
