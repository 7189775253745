import logo from "../assets/new images/logo.svg";
import React, { useEffect, useState } from "react";
import API from "../api";
import { useQuery } from "../hooks";
import { useHistory } from "react-router-dom";
import checkGif from "../assets/new images/checkGif.gif";

function RejectBooking() {
  let query = useQuery();
  const history = useHistory();
  const [generalComments, setGeneralComments] = useState("");
  const [showError, setShowError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [remainingChars, setRemainingChars] = useState(40);

  const showConfirmationModal = (message) => {
    setConfirmationMessage(message);
    setShowModal(true);

    // Ocultar el modal después de unos segundos (por ejemplo, 3 segundos)
    setTimeout(() => {
      setShowModal(false);
      setConfirmationMessage("");
      history.push("/"); // Redirigir a la pantalla principal después de ocultar el modal
    }, 3000); // 3000 milisegundos = 3 segundos
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const handleTextareaChange = (event) => {
    const inputText = event.target.value;
    setGeneralComments(inputText);
    const charsLeft = 40 - inputText.length;
    setRemainingChars(charsLeft >= 0 ? charsLeft : 0); // Asegura que remainingChars no sea negativo
  };

  const inputLength = generalComments.length;

  const sendReject = async () => {
    const formData = {
      reason: generalComments,
    };
    try {
      const response = await API.sendRejectReservation(
        query.get("reservation").replace("/", ""),
        formData
      );
      console.log(response.status);
      setShowError("");
      showConfirmationModal("La reserva se ha rechazado correctamente.");
      return true;
    } catch (error) {
      setShowError(error.error);
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        width: windowWidth >= 1024 ? "50%" : "100%",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <img src={logo} alt="Logo" style={{ width: 164, marginBottom: 40 }} />
      <h2
        style={{
          color: "var(--1, #CE2F15)",
          textAlign: "center",
          fontSize: 25,
          fontWeight: "700",
          lineHeight: "33px",
          marginBottom: "20px",
          fontFamily: "Nunito Sans",
        }}
      >
        Rechazar reserva
      </h2>
      <p
        style={{
          color: "#000",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "400",
          lineHeight: "21px",
          marginBottom: "30px",
          width: "90%",
        }}
      >
        Por favor, deja saber al comensal los motivos de tu rechazo(Min. 40
        caracteres):
      </p>
      <textarea
        placeholder="Escribí tus comentarios"
        value={generalComments}
        style={{
          border: "0.649px solid #E5E5E5",
          borderRadius: 4,
          width: "90%",
          height: 100,
          fontSize: 12,
          fontWeight: "600",
          lineHeight: "16px",
          color: "#262626",
          marginTop: 10,
          padding: 10,
        }}
        onChange={handleTextareaChange}
        minLength={40}
      />
      {remainingChars > 0 && ( // Muestra el texto solo si quedan caracteres por escribir
        <p
          style={{
            color: "#969B91",
            fontSize: 12,
            fontWeight: "400",
            margin: 0,
            marginRight: "auto",
            marginLeft: 20,
          }}
        >
          Caracteres restantes: {remainingChars}
        </p>
      )}
      <button
        style={{
          borderRadius: "4px",
          background: "var(--1, #CE2F15)",
          color: "#FFF",
          fontSize: 16,
          fontWeight: "600",
          lineHeight: "20px",
          width: "90%",
          padding: 17,
          opacity: inputLength < 40 ? 0.6 : 1,
          cursor: inputLength < 40 ? "not-allowed" : "pointer",
        }}
        onClick={sendReject}
        disabled={inputLength < 40}
      >
        Confirmar rechazo
      </button>
      <p
        style={{
          display: !showError ? "none" : "block",
          color: "#CE2F15",
          fontSize: 16,
          fontWeight: "600",
          lineHeight: "14px",
          marginTop: 0,
        }}
      >
        {showError}
      </p>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              padding: 20,
              borderRadius: 8,
              maxWidth: 400,
              textAlign: "center",
              color: "#27A576",
              fontSize: 18,
              fontWeight: "700",
              lineHeight: "22px",
              margin: 20,
            }}
          >
            <img src={checkGif} alt="Check Image" style={{ width: 150 }} />
            <p>{confirmationMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RejectBooking;
