import React from "react";
import Slider, {SliderTooltip} from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useState } from "react";

const Filter = () => {
    const { Handle } = Slider;
    const [range, setrange] = useState(500000);
    const [checked1, setchecked1] = useState(null);
    const [checked2, setchecked2] = useState(null);
    const [checked3, setchecked3] = useState(null);
    const [prov_type1, setprov_type1] = useState(null)
    const [prov_type2, setprov_type2] = useState(null)
    const handleChange1 = () => {
      let a= checked1;
      setchecked1(!a);
      setchecked2(false);
      setchecked3(false);
    }
    const handleChange2 = () => {
      let a= checked2;
      setchecked2(!a);
      setchecked1(false);
      setchecked3(false);
    }
    const handleChange3 = () => {
      let a= checked3;
      setchecked3(!a);
      setchecked2(false);
      setchecked1(false);
    }

    const handleprov1 = () => {
      let a=prov_type1;
      setprov_type1(!a);
      setprov_type2(false)
    }
    const handleprov2 = () => {
      let a=prov_type2;
      setprov_type2(!a);
      setprov_type1(false)
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        
        return (
          <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`Gs. ${numberWithCommas(value)}`}
            visible={dragging}
            placement="top"
            key={index}
          >
            <Handle value={value} {...restProps} />
          </SliderTooltip>
        );
      };
   
    return (
        <div className="white-bg">
            <h2 className="baseM-text color-black">Ordenar por</h2>
            <h2 className="baseM-text color-black">Filtrar por</h2>
            <div className="is-flex is-flex-direction-row">
              <div className="is-flex is-flex-direction-column">
                <p>Rango de precios</p>
                <p className="overline">POR PERSONA</p>
              </div>
              <div className="is-flex is-flex-direction-column">
                <p>Hasta</p>
                <p className="overline">{`Gs ${range}`}</p>
              </div>
            </div>
            <Slider min={0} max={500000} defaultValue={range} step={50000} handle={handle} onChange={(e)=>{setrange(e)}}/>
            <div>
              <p className="overline">Tipo de Comida</p>
              <div className="is-flex is-flex-direction-column">
                <label>
                  <input
                    type="checkbox"
                    checked={checked1}
                    onChange={handleChange1}
                  />
                  Street food
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={checked2}
                    onChange={handleChange2}
                  />
                  Cocina tradicional
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={checked3}
                    onChange={handleChange3}
                  />
                  Alta Cocina 
                </label>            
              </div>
            </div>
            <div>
            <p className="overline">Tipo de Proveedor</p>
            <div className="is-flex is-flex-direction-column">
            <label>
                  <input
                    type="checkbox"
                    checked={prov_type1}
                    onChange={handleprov1}
                  />
                  Catering
                </label>  
              <label>
                <input
                  type="checkbox"
                  checked={prov_type2}
                  onChange={handleprov2}
                />
                  Chef Particular
              </label>  
            </div>
            </div>
        </div>
        
    );

}

export default Filter;
