import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./RestaurantCard.css";
import Tag from "./Tag";
import DayCircle from "./DayCircle";
import people from "../assets/new images/users.svg";
import calendar from "../assets/new images/calendar.svg";
import clock from "../assets/new images/clock.svg";
import sparkles from "../assets/new images/sparkles-solid 1.svg";

const RestaurantCard = ({
  id,
  title,
  isNew,
  imageSrc,
  peopleCount,
  daysAvailable,
  services,
  tags,
  price,
}) => {
  // Define el objeto de estado que quieres pasar a ChefDetailsScreen
  const stateObject = {
    id,
    title,
    isNew,
    imageSrc,
    peopleCount,
    daysAvailable,
    services,
    tags,
    price,
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  return (
    <Link
      to={{ pathname: "/select-menu", state: stateObject }}
      className="restaurant-card"
      style={{ width: windowWidth >= 1024 ? "50%" : "100%" }}
    >
      <div className="restaurant-info">
        <h2
          className="title"
          style={{
            paddingLeft: windowWidth >= 1024 ? "28.5%" : 97,
            fontFamily: "Nunito Sans",
          }}
        >
          {title}
          {isNew && (
            <p className="new">
              <img
                src={sparkles}
                alt="sparkles"
                style={{ width: 10, height: 10 }}
              />
              Nuevo
            </p>
          )}
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <img src={imageSrc} alt="Imagen del restaurante" className="img" />
          <div className="details">
            <div className="people-count">
              <img src={people} alt="people" style={{ width: 9, height: 9 }} />
              {peopleCount}
            </div>

            {/*
              <div className="days">
                <img
                  src={calendar}
                  alt="calendar"
                  style={{ width: 12, height: 12 }}
                />
                {daysAvailable.map((day, index) => (
                  <DayCircle key={index} day={day.day} enabled={day.enabled} />
                ))}
              </div>
                */}
            <span className="services">
              <img src={clock} alt="clock" style={{ width: 12, height: 12 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {services}
              </div>
            </span>
            <div className="tags">
              {tags.map((tag, index) => (
                <Tag key={index} text={tag.name} />
              ))}
            </div>
          </div>

          <div className="price-info-container">
            <span className="price-info">A partir de</span>
            <span className="price">Gs. {price}</span>
            <span className="price-info-bold">por persona</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default RestaurantCard;
