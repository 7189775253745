import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import loader from '../assets/lottie/search.json';
const Check = (props) => {
    const container = useRef(null);

    useEffect(() => {
        lottie
            .loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require(`../assets/lottie/check.json`),
            })
            .setSpeed(1);
    }, []);

    return (
        <div style={{ top: '50%', position: 'inherit', display: 'flex' }}>
            <div className="container" ref={container}></div>
        </div>
    );
};

export default Check;
