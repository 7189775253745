import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';
import personSharp from '../assets/images/person-sharp.svg';
import API from '../api';
import Appbar from '../components/Appbar';
import calendar from '../assets/images/calendar-clear-outline.svg';
import { format, parse } from 'date-fns';
import smallLogo from '../assets/images/small-logo.svg';
import Check from '../components/Check';
import ErrorPage from '../components/ErrorPage';
import Gallery from '../components/Gallery';

const Confirmation = (props) => {
    let history = useHistory();
    const events = [
        'Reunión Social',
        'Cumpleaños',
        'Evento Corporativo',
        'Otro',
    ];
    const params = new URLSearchParams(useLocation().search);
    const [person, setperson] = useState(props.location.state.person);
    const [chef, setChef] = useState(null);
    const [date, setdate] = useState(props.location.state.date);
    const [menuName, setMenuName] = useState(props.location.state?.menuName);
    const [menuIndex, setmenuIndex] = useState(props.location.state?.menuIndex);
    const [menuPrice, setMenuPrice] = useState(props.location.state.menuPrice);
    const [people_qty, setpeople_qty] = useState(
        props.location.state.people_qty
    );
    const [event, setevent] = useState(props.location.state.event);
    const [eventName, seteventName] = useState(
        props.location.state.event_other
    );
    const [observations, setObservations] = useState('');
    const [confirmation, setConfirmation] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [hour, sethour] = useState(props.location.state.hour);
    const [error, seterror] = useState(false);
    const [errorStr, setErrorStr] = useState(null);
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    useEffect(() => {
        setLoadingData(true);
        API.getServiceProvider(props.location.state.chefId).then((provider) => {
            setChef(provider);
            console.log(chef);
            setLoadingData(false);
        });
        setLoadingData(false);
    }, [props.location.state.chefId]);

    const makeReservation = () => {
        API.createOrder({
            first_name: person.first_name,
            last_name: person.last_name,
            phone: person.phone,
            email: person.email,
            ruc: person.ruc,
            chosen_date: date,
            menu: menuIndex,
            diners_qty: parseInt(people_qty),
            observations: observations,
            has_accepted_terms: true,
            moment_of_day: hour,
            occasion: event,
            occasion_other: eventName,
        })
            .then((res) => {
                console.log(res);
                setConfirmation(true);
            })
            .catch((e) => {
                setErrorStr(e);
                seterror(true);
            });
    };

    return (
        <>
            {error && <ErrorPage errorStr={errorStr} />}
            {!error && !confirmation ? (
                <div className="is-flex is-flex-direction-column animate__animated animate__fadeIn">
                    <Appbar />
                    <div
                        className="is-flex is-flex-direction-column m-3 mt-5 p-5"
                        style={{
                            background: '#572821',
                            borderRadius: '15px',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            alignSelf: 'center',
                            width: '90%',
                            maxWidth: '500px',
                        }}
                    >
                        <div className="mb-6">
                            <div className="title" style={{ color: 'white' }}>
                                <p>
                                    {chef?.menus.map((menu) =>
                                        menu.id === parseInt(menuIndex)
                                            ? menu.title
                                            : ''
                                    )}
                                </p>
                                {/* Tarjeta de Reserva */}
                            </div>
                        </div>
                        <div className="is-flex is-justify-content-space-between mt-4">
                            <div
                                className="is-flex is-flex-direction-column"
                                style={{
                                    maxWidth: '500px',
                                    alignSelf: 'center',
                                }}
                            >
                                <p
                                    style={{
                                        color: 'var(--secondary2)',
                                        letterSpacing: '3px',
                                        fontSize: '10px',
                                    }}
                                >
                                    CHEF
                                </p>
                                <p style={{ color: 'white', fontSize: '14px' }}>
                                    {chef?.name}
                                </p>
                            </div>
                            <div className="is-flex is-flex-direction-column">
                                <p
                                    style={{
                                        color: 'var(--secondary2)',
                                        letterSpacing: '3px',
                                        fontSize: '10px',
                                        alignSelf: 'flex-end',
                                    }}
                                >
                                    TOTAL
                                </p>
                                <p style={{ color: 'white', fontSize: '14px' }}>
                                    Gs.{' '}
                                    {numberWithCommas(
                                        parseInt(menuPrice) *
                                            parseInt(people_qty)
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            width: '95%',
                            maxWidth: '500px',
                            alignSelf: 'center',
                        }}
                    >
                        <div className=" mt-5 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center px-5">
                            <div className="is-flex is-flex-direction-column">
                                <p
                                    style={{
                                        color: 'var(--secondary2)',
                                        letterSpacing: '3px',
                                        fontSize: '10px',
                                    }}
                                >
                                    FECHA
                                </p>
                                <p style={{ color: 'white', fontSize: '14px' }}>
                                    {`${format(
                                        parse(date, 'yyyy-MM-dd', new Date()),
                                        'dd-MM-yyyy'
                                    )}`}
                                </p>
                            </div>
                            <div className="is-flex is-flex-direction-column">
                                <p
                                    style={{
                                        color: 'var(--secondary2)',
                                        letterSpacing: '3px',
                                        fontSize: '10px',
                                    }}
                                >
                                    TIPO DE EVENTO
                                </p>
                                <p style={{ color: 'white', fontSize: '14px' }}>
                                    {events[props.location.state.event - 1]}
                                </p>
                            </div>
                            <div className="is-flex is-flex-direction-column">
                                <p
                                    style={{
                                        color: 'var(--secondary2)',
                                        letterSpacing: '3px',
                                        fontSize: '10px',
                                    }}
                                >
                                    CANTIDAD
                                </p>
                                <div className="is-flex is-flex-direction-row">
                                    <img
                                        src={personSharp}
                                        alt="Cantidad de personas"
                                    />
                                    <p
                                        className="px-2"
                                        style={{
                                            color: 'white',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {people_qty}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`px-5 ${error ? `` : `py-3`}`}
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            alignSelf: 'center',
                        }}
                    >
                        <p
                            style={{
                                color: 'white',
                                letterSpacing: '1px',
                                fontSize: '13px',
                            }}
                            className={`${error ? `py-2` : `hiding`}`}
                        >
                            * La cantidad de personas es menor al mínimo
                            requerido por el Chef.
                        </p>
                    </div>
                    <div
                        className="is-flex is-flex-direction-column px-5 pb-5"
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            alignSelf: 'center',
                        }}
                    >
                        <p
                            style={{
                                color: 'var(--secondary2)',
                                letterSpacing: '3px',
                                fontSize: '13px',
                            }}
                        >
                            OBSERVACIONES
                        </p>
                        <p
                            style={{
                                color: 'white',
                                letterSpacing: '1px',
                                fontSize: '11px',
                                fontWeight: '400',
                            }}
                        >
                            Ejemplo: Alguna intolerancia o alergia.
                        </p>
                        <textarea />
                    </div>
                    <div className="is-flex is-justify-content-center">
                        <button
                            onClick={makeReservation}
                            className="white-button mt-2 h-cursor zoom"
                        >
                            Realizar Reserva
                        </button>
                    </div>
                </div>
            ) : (
                <div
                    className={`${
                        error
                            ? `is-hidden`
                            : `is-flex is-align-items-center is-justify-content-center `
                    }`}
                >
                    <div
                        className="is-flex is-flex-direction-column is-justify-content-space-evenly is-align-items-center px-5 animate__animated animate__zoomIn"
                        style={{
                            height: '60vh',
                            marginTop: '160px',
                        }}
                    >
                        <Check />
                        <div
                            className="baseXL-black-text"
                            style={{
                                color: 'white',
                                textAlign: 'center',
                                lineHeight: '30px',
                            }}
                        >
                            ¡Agendamiento realizado con éxito!
                        </div>
                        <p
                            style={{
                                color: 'white',
                                textAlign: 'center',
                                lineHeight: '30px',
                                width: '80vw',
                                maxWidth: '400px',
                            }}
                        >
                            Enviamos un mensaje de confirmación a{' '}
                            <b>{person.email}</b> con todos los detalles de tu
                            agendamiento.
                        </p>
                        <button
                            onClick={() => history.push({ pathname: '/' })}
                            className="white-button my-5 h-cursor zoom"
                        >
                            Volver al inicio
                        </button>
                        <img src={smallLogo} className="mt-6" alt="Buscochef" />
                    </div>
                </div>
            )}
        </>
    );
};

export default Confirmation;
