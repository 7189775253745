import "./ChefScreen.css";
import logo from "../assets/new images/logo.svg";
import sortLogo from "../assets/new images/sort.svg";
import RestaurantCard from "../components/RestaurantCard";
import sliders from "../assets/new images/sliders.svg";
import { useEffect, useState } from "react";
import API from "../api";
import FilterModal from "../components/FilterModal";
import loader from "../assets/new images/buscoChefLoader.gif";

const ChefScreen = () => {
  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const [serviceProviders, setServiceProviders] = useState(null);
  const [serviceTypes, setServiceTypes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("ordering") || "name";
  });

  const [sortOrder, setSortOrder] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const ordering = params.get("ordering") || "";
    return ordering.startsWith("-") ? "desc" : "asc";
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const fetchServiceProviders = async (filters = {}) => {
    try {
      setLoading(true);
      const serviceProvidersData = await API.getServiceProviders({
        ...filters, // Mantener los filtros existentes
      });
      setServiceProviders(serviceProvidersData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching service providers:", error);
      setLoading(false);
    }
  };

  const fetchServiceTypes = async () => {
    try {
      const serviceTypesData = await API.getServiceTypes(); // Llamar a la API para obtener los tipos de servicio
      setServiceTypes(serviceTypesData);
    } catch (error) {
      console.error("Error fetching service types:", error);
    }
  };

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  const toggleSortOrder = () => {
    // Cambiar el tipo de orden de ascendente a descendente y viceversa
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const applyFilters = (filters) => {
    fetchServiceProviders(filters); // Aplicar los filtros con el nuevo orden
  };

  return (
    <div
      className="chef-screen"
      style={{ alignItems: windowWidth >= 1024 ? "center" : "normal" }}
    >
      <img src={logo} alt="Logo" className="logo" />

      <div style={{ width: windowWidth >= 1024 ? "40%" : "100%" }}>
        <button className="filter-button" onClick={() => setShowFilters(true)}>
          <img src={sliders} alt="sliders" />
          Aplicar Filtros
        </button>
      </div>

      <div
        className="chef-info"
        style={{
          justifyContent:
            windowWidth >= 1024 ? "space-around" : "space-between",
          width: windowWidth >= 1024 ? "70%" : "auto",
        }}
      >
        <span className="count-text">
          {serviceProviders?.length} Chefs disponibles
        </span>

        <img
          src={sortLogo}
          alt="sort-logo"
          className="sort-logo"
          onClick={() => {
            toggleSortOrder();
          }}
        />
      </div>
      {/* Mostrar el loader mientras se cargan los datos */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            paddingBottom: "40%",
          }}
        >
          <img src={loader} alt="loader" style={{ width: 182 }} />
          <p
            style={{
              fontSize: 18,
              fontWeight: "700",
              lineHeight: " 22px",
              color: "#CE2F15",
            }}
          >
            Cargando...
          </p>
        </div>
      ) : (
        // Renderizar los datos una vez que se hayan cargado
        serviceProviders?.map((s) => (
          <RestaurantCard
            id={s.id}
            key={s.id}
            title={s.name}
            isNew={s.new}
            imageSrc={s.profile_picture}
            peopleCount={`Min. ${s.min_diners} personas`}
            daysAvailable={[
              { day: "L", enabled: s.availability.monday },
              { day: "M", enabled: s.availability.tuesday },
              { day: "M", enabled: s.availability.wednesday },
              { day: "J", enabled: s.availability.thursday },
              { day: "V", enabled: s.availability.friday },
              { day: "S", enabled: s.availability.saturday },
              { day: "D", enabled: s.availability.sunday },
            ]}
            services={`Servicios ${s.availability.morning ? "mañana" : ""} ${
              s.availability.noon ? "mediodía" : ""
            } ${s.availability.afternoon ? "tarde" : ""} ${
              s.availability.night ? "noche" : ""
            } `}
            tags={s.service_types}
            price={formatPrice(s.starting_price)}
          />
        ))
      )}
      <FilterModal
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        onApplyFilters={applyFilters}
        serviceTypes={serviceTypes}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
      />
    </div>
  );
};

export default ChefScreen;
