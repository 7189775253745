import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import loader from "../assets/lottie/construction.json";
const Construction = (props) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require(`../assets/lottie/construction.json`),
    });
  }, []);

  return (
    <div
      style={{
        top: "30%",
        position: "inherit",
        display: "flex",
        maxWidth: "500px",
      }}
    >
      <div
        className="container is-flex is-align-items-center"
        ref={container}
      ></div>
    </div>
  );
};

export default Construction;
