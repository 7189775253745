import 'react-dropdown/style.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import AppBar from '../components/Appbar';
import clock from '../assets/images/clock.svg';
import user from '../assets/images/user.svg';
import switcher from '../assets/images/options.svg';
import Dropdown from 'react-dropdown';
import { startOfMonth, addMonths } from 'date-fns';

import ChefCard from '../components/ChefCard';
import { endOfMonth, eachDayOfInterval, format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { useLocation } from 'react-router';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import arrmleft from '../assets/images/arrow-month-left.svg';
import arrmright from '../assets/images/arrow-month-right.svg';
import Loading from '../components/Loading';

import ArrowUp from '../assets/images/arrowUp.svg';
import ArrowDown from '../assets/images/arrowDown.svg';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import useDrag from '../components/useDrag.ts';
import moment from 'moment';
import Joyride from 'react-joyride';
import radioButton from '../assets/images/radio-button-on-outline.svg';

const styles = {
    dateButton: {
        color: '#9E9E9E',
        borderBottom: '2px solid #FFFFFF',
        userSelect: 'none',
        cursor: 'grab',
    },
    selectedDateButton: {
        color: '#CE2F15',
        borderBottom: '3px solid #CE2F15',
        userSelect: 'none',
        cursor: 'grab',
    },
    weekDay: {
        fontSize: '12px',
        fontWeight: 500,
    },
    date: {
        fontWeight: 600,
        fontSize: '16px',
    },
};

const Event = (props) => {
    let history = useHistory();
    const params = new URLSearchParams(useLocation().search);
    const selectedDate = moment(params.get('date')).toDate();
    /* Datepicker */
    const [actual, setActual] = useState(0);

    const [selected, setSelected] = useState(
        `${selectedDate.toDateString().split(' ')[0]}${
            selectedDate.toDateString().split(' ')[2]
        }`
    );
    const options = ['Noche', 'Mediodia', 'Tarde', 'Mañana'];
    const [hour, sethour] = useState(1);
    const [people_qty, setpeople_qty] = useState(params.get('people_qty'));
    const [results, setResults] = useState([]);
    const [active, setactive] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [date, setDate] = useState(format(selectedDate, 'yyyy-MM-dd'));
    const [showQty, setshowQty] = useState(false);
    const [filters, setfilters] = useState(false);
    const [food, setfood] = useState(0);
    const [type, setType] = useState(0);
    const [range, setrange] = useState(500000);
    const [tempqty, settempqty] = useState(people_qty);
    const [firsttime, setFirsttime] = useState();
    const [isPeopleNotSelected, setIsPeopleNotSelected] = useState(true);
    const steps = [
        {
            target: '._date',
            content:
                '¡Empeza seleccionando la fecha en la que queres realizar tu evento! 🥳',
            disableBeacon: true,
        },
        {
            target: '._hour',
            content: '¡Escoge la hora en la que se estaría realizando! ⏰',
            disableBeacon: true,
        },
        {
            target: '._person',
            content:
                '¡Seleccioná la cantidad de personas que tendría tu evento! 👥',
            disableBeacon: true,
        },
        {
            target: '._filters',
            content:
                'Si deseás podes filtrar las opciones de acuerdo a precio, tipo de proveedor o tipo de comida 👌',
            disableBeacon: true,
        },
        {
            target: '._chef',
            content: '¡Seleccioná un chef o proveedor para tu evento! 👨‍🍳',
            disableBeacon: true,
        },
    ];
    useEffect(() => {
        const storage = localStorage.getItem('firsttime');
        if (!storage) {
            localStorage.setItem('firsttime', 'true');
            setFirsttime(true);
        } else {
            setFirsttime(false);
        }
    }, []);

    useEffect(() => {
        let searchParams;
        searchParams = {
            date: date,
            diners_qty: people_qty,
        };

        if (food !== 0) {
            searchParams.service_type = food;
        }
        if (type !== 0) {
            searchParams.provider_type = type;
        }
        setLoadingData(true);
        setshowQty(false);
        if (people_qty) {
            history.replace(`/search?people_qty=${people_qty}&date=${date}`);
        } else {
            history.replace(`/search?date=${date}`);
        }

        api.search(searchParams).then((data) => {
            setResults(data);
            setLoadingData(false);
        });
    }, [
        date,
        people_qty,
        hour,
        food,
        type,
        range,
        history,
        setIsPeopleNotSelected,
    ]);

    const onSelectDate = (newDate) => {
        setDate(newDate);
    };

    const monthPicker = (pick) => {
        if (pick === 'back') {
            let posBack = actual;
            posBack--;
            posBack = posBack < 0 ? arrlen : posBack;
            setMonth(meses[posBack]);
            setActual(posBack);
        } else if (pick === 'next') {
            let posNext = actual;
            posNext++;
            posNext = posNext > arrlen ? 0 : posNext;
            setMonth(meses[posNext]);
            setActual(posNext);
        }
    };
    const onDateClick = (d, weekDay, date) => {
        setSelected(`${weekDay}${date}`);
        const datenew = format(d, 'yyyy-MM-dd');
        onSelectDate(datenew);
    };

    const dateComponent = (d) => {
        const weekDay = d.toDateString().split(' ')[0];
        const date = d.toDateString().split(' ')[2];
        return (
            <div
                key={date}
                className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mx-2 px-2 white-bg h-cursor"
                style={
                    `${weekDay}${date}` === selected
                        ? styles.selectedDateButton
                        : styles.dateButton
                }
                onClick={() => {
                    onDateClick(d, weekDay, date);
                }}
            >
                <span className="" style={styles.weekDay}>
                    {weekDay}
                </span>
                <span style={styles.date}>{date}</span>
            </div>
        );
    };
    /* EVENT*/

    const handleActive = (isActive) => {
        if (!active) setactive(true);
        else setactive(false);
    };

    let month1 = eachDayOfInterval({
        start: addDays(new Date(), 2),
        end: addDays(new Date(endOfMonth(new Date(), { locale: es })), 0),
    });

    let month2 = eachDayOfInterval({
        start: addMonths(new Date(startOfMonth(new Date(), { locale: es })), 1),
        end: addMonths(new Date(endOfMonth(new Date(), { locale: es })), 1),
    });

    let month3 = eachDayOfInterval({
        start: addMonths(new Date(startOfMonth(new Date(), { locale: es })), 2),
        end: addMonths(new Date(endOfMonth(new Date(), { locale: es })), 2),
    });
    if (month1[0].getMonth() === month2[0].getMonth()) {
        month1 = month2;
        month2 = month3;
        month3 = eachDayOfInterval({
            start: addMonths(
                new Date(startOfMonth(new Date(), { locale: es })),
                3
            ),
            end: addMonths(new Date(endOfMonth(new Date(), { locale: es })), 3),
        });
    }

    let meses = [month1, month2, month3];
    const [month, setMonth] = useState(meses[actual]);
    const arrlen = meses.length - 1;

    let upChange = (e) => {
        let a = tempqty;
        a++;
        settempqty(a);
    };
    let downChange = (e) => {
        let a = tempqty;
        if (a <= 0) {
            a = '0';
        } else {
            a--;
        }
        settempqty(a);
    };

    const { Handle } = Slider;

    const [checked1, setchecked1] = useState(false);
    const [checked2, setchecked2] = useState(false);
    const [checked3, setchecked3] = useState(false);
    const [prov_type1, setprov_type1] = useState(false);
    const [prov_type2, setprov_type2] = useState(false);

    const handleChange = (e) => {
        if (e === 1) {
            let a = !checked1;
            setchecked1(a);
            setchecked2(false);
            setchecked3(false);
            a ? setfood(1) : setfood(0);
        } else if (e === 2) {
            let a = !checked2;
            setchecked2(a);
            setchecked1(false);
            setchecked3(false);
            a ? setfood(2) : setfood(0);
        } else if (e === 3) {
            let a = !checked3;
            setchecked3(a);
            setchecked2(false);
            setchecked1(false);
            a ? setfood(3) : setfood(0);
        }
    };

    let chefI = 0;
    const handleprov1 = () => {
        let a = !prov_type1;
        setprov_type1(a);
        setprov_type2(false);
        a ? setType(1) : setType(0);
    };
    const handleprov2 = () => {
        let a = !prov_type2;
        setprov_type2(a);
        setprov_type1(false);
        a ? setType(2) : setType(0);
    };
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;

        return (
            <SliderTooltip
                prefixCls="rc-slider-tooltip"
                overlay={`Gs. ${numberWithCommas(value)}`}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        );
    };
    const handleHour = (e) => {
        let h = e.value;
        if (h === 'Noche') {
            sethour('1');
        } else if (h === 'Mediodia') {
            sethour('2');
        } else if (h === 'Tarde') {
            sethour('3');
        } else if (h === 'Mañana') {
            sethour('4');
        }
    };
    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const handleDrag =
        ({ scrollContainer }) =>
        (e) =>
            dragMove(e, (posDiff) => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });
    const [frameselected, setframeSelected] = React.useState('');
    const handleItemClick = (itemId) => () => {
        if (dragging) {
            return false;
        }
        setframeSelected(selected !== itemId ? itemId : '');
    };

    const optionsStyle = {
        backgroundColor: 'black',
    };
    return (
        <>
            {firsttime ? <Joyride steps={steps} /> : null}

            <div className=" ">
                <div className="is-flex is-flex-direction-column d-static animate__animated animate__fadeIn">
                    <div
                        style={{ position: 'fixed', top: 0, right: 0, left: 0 }}
                    >
                        <AppBar />
                        <div className="_date">
                            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between px-5 py-2 white-bg d-static">
                                <img
                                    className="h-cursor"
                                    src={arrmleft}
                                    alt="Mes Anterior"
                                    onClick={() => monthPicker('back')}
                                />
                                <h2 className="medium-text black-text">
                                    {`${
                                        month[0].toDateString().split(' ')[1]
                                    } - ${
                                        month[0].toDateString().split(' ')[3]
                                    }`}{' '}
                                </h2>
                                <img
                                    className="h-cursor"
                                    src={arrmright}
                                    alt="Mes Siguiente"
                                    onClick={() => monthPicker('next')}
                                />
                            </div>
                            <ScrollMenu
                                onScroll={() => {}}
                                LeftArrow={() => <div />}
                                RightArrow={() => <div />}
                                onInit={() => null}
                                onWheel={() => null}
                                onMouseDown={() => dragStart}
                                onMouseUp={() => dragStop}
                                onMouseMove={handleDrag}
                            >
                                {month.map((d) => dateComponent(d))}
                            </ScrollMenu>
                        </div>

                        <div className="is-flex white-bg is-justify-content-center">
                            <div className="is-flex is-align-items-center px-2 pb-3 is-justify-content-space-evenly">
                                <div
                                    className="option-div is-flex is-justify-content-center m-1 px-6 _hour"
                                    style={{ maxWidth: '800px' }}
                                >
                                    <img
                                        src={clock}
                                        style={{ padding: '6px' }}
                                        alt="Horario"
                                    />
                                    {/* <CustomSelector /> */}
                                    <Dropdown
                                        arrowClosed={
                                            <span className="bi bi-chevron-down"></span>
                                        }
                                        arrowOpen={
                                            <span className="bi bi-chevron-up"></span>
                                        }
                                        controlClassName="is-flex is-justify-content-space-between is-align-items-center d-control"
                                        placeholderClassName="is-flex is-justify-content-center"
                                        className="m-2"
                                        options={options}
                                        value={options[0]}
                                        placeholder={options[0]}
                                        onChange={handleHour}
                                    />
                                </div>
                                <div
                                    className={
                                        ` dropdown ` +
                                        (active ? 'is-active' : 'dp')
                                    }
                                >
                                    <div className="dropdown-trigger">
                                        <button
                                            className="button  qty"
                                            aria-haspopup="true"
                                            aria-controls="dropdown-menu2"
                                            style={{ width: '80px' }}
                                            onClick={() => {
                                                handleActive(active);
                                                setshowQty(!showQty);
                                            }}
                                        >
                                            <div
                                                className="_person is-flex is-flex-direction-row is-justify-content-space-around is-align-otems-center"
                                                style={{ width: '60px' }}
                                            >
                                                <img
                                                    src={user}
                                                    alt="Cantidad de Personas"
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        height: '15px',
                                                        marginTop: '2px',
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        color: 'white',
                                                        fontWeight: 'normal',
                                                        fontSize: '14px',
                                                        width: '40px',
                                                        height: '18px',
                                                    }}
                                                >
                                                    {people_qty
                                                        ? people_qty
                                                        : '-'}
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="_filters filter-div is-flex is-align-items-center is-justify-content-center m-1"
                                    onClick={() => {
                                        setfilters(!filters);
                                    }}
                                >
                                    <img
                                        src={switcher}
                                        alt="Cantidad de Personas"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                filters
                                    ? 'is-flex is-flex-direction-column px-6 py-3 '
                                    : 'is-flex is-flex-direction-column hiding px-5 py-4 '
                            }
                            style={{ backgroundColor: '#EEEEEE' }}
                        >
                            <h2 className="baseM-text color-black pb-2">
                                Filtrar por
                            </h2>
                            <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                                <div className="is-flex is-flex-direction-column">
                                    <p className="baseS-black-text ">
                                        Rango de precios
                                    </p>
                                    <p className="baseXS-text">*Por persona</p>
                                </div>
                                <div className="is-flex is-flex-direction-column">
                                    <p
                                        className="overline p-0"
                                        style={{ color: 'black' }}
                                    >
                                        Hasta
                                    </p>
                                    <p
                                        className="baseXS-black-text p-2 white-bg"
                                        style={{ borderRadius: '10px' }}
                                    >{`Gs ${range}`}</p>
                                </div>
                            </div>
                            <div className="py-2">
                                <Slider
                                    min={0}
                                    max={500000}
                                    defaultValue={range}
                                    step={50000}
                                    handle={handle}
                                    onChange={(e) => {
                                        setrange(e);
                                    }}
                                />
                            </div>

                            <div>
                                <p
                                    className="overline pb-1"
                                    style={{ color: 'black' }}
                                >
                                    Tipo de Comida
                                </p>
                                <div
                                    className={
                                        'is-flex is-flex-direction-column pb-2'
                                    }
                                >
                                    <label>
                                        <input
                                            className="cb"
                                            type="checkbox"
                                            checked={checked1}
                                            onChange={() => {
                                                handleChange(1);
                                            }}
                                        />
                                        Cocina tradicional
                                    </label>
                                    <label>
                                        <input
                                            className="cb"
                                            type="checkbox"
                                            checked={checked2}
                                            onChange={() => {
                                                handleChange(2);
                                            }}
                                        />
                                        Alta Cocina
                                    </label>
                                    <label>
                                        <input
                                            className="cb"
                                            type="checkbox"
                                            checked={checked3}
                                            onChange={() => {
                                                handleChange(3);
                                            }}
                                        />
                                        Street Food
                                    </label>
                                </div>
                            </div>
                            <div>
                                <p
                                    className="overline pb-1"
                                    style={{ color: 'black' }}
                                >
                                    Tipo de Proveedor
                                </p>
                                <div className="is-flex is-flex-direction-column">
                                    <label>
                                        <input
                                            className="cb"
                                            type="checkbox"
                                            checked={prov_type1}
                                            onChange={handleprov1}
                                        />
                                        Chef Particular
                                    </label>
                                    <label>
                                        <input
                                            className="cb"
                                            type="checkbox"
                                            checked={prov_type2}
                                            onChange={handleprov2}
                                        />
                                        Catering
                                    </label>
                                </div>
                                <button
                                    className="red-button h-cursor zoom"
                                    style={{ border: `0px` }}
                                    onClick={() => {
                                        setfilters(false);
                                    }}
                                >
                                    Aplicar
                                </button>
                            </div>
                        </div>
                        <div
                            className={
                                showQty
                                    ? `people-selector`
                                    : `people-selector hiding`
                            }
                        >
                            <div className=" is-flex is-flex-direction-row  is-justify-content-space-evenly is-align-items-center  py-3">
                                <p>Cantidad de personas</p>
                                <div className="is-flex is-flex-direction-row ">
                                    <input
                                        type="number"
                                        className="people-Qty"
                                        value={tempqty}
                                        onChange={(e) =>
                                            settempqty(e.target.value)
                                        }
                                    />
                                    <div className="is-flex is-flex-direction-column is-justify-content-space-evenly">
                                        <img
                                            src={ArrowUp}
                                            className="chevron"
                                            alt="Cantidad de personas"
                                            onClick={upChange}
                                        />
                                        <img
                                            src={ArrowDown}
                                            className="chevron"
                                            alt="Cantidad de personas"
                                            onClick={downChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="ok-button is-flex is-flex-direction-row is-justify-content-center"
                                onClick={() => {
                                    setpeople_qty(tempqty);
                                    setshowQty(false);
                                    setIsPeopleNotSelected(true);
                                }}
                            >
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loadingData && !filters && (
                        <div style={{ marginTop: '290px', zIndex: '-1' }}>
                            <div style={{ zIndex: 0 }}>
                                <Loading />
                            </div>
                        </div>
                    ) /* Reempl. null x loading */
                }
                {!loadingData && (
                    <div
                        className={
                            filters
                                ? `_chef is-flex is-flex-direction-column is-align-items-center mb-2 hiding`
                                : `_chef is-flex is-flex-direction-column is-align-items-center mb-2`
                        }
                        style={{ marginTop: '290px ', zIndex: 0 }}
                    >
                        {!isPeopleNotSelected ? (
                            <p
                                className="mx-3 px-3 mb-2"
                                style={{ color: 'white' }}
                            >
                                * Favor Seleccionar la cantidad de personas
                            </p>
                        ) : null}

                        {range === 0
                            ? results.map((r) => (
                                  <ChefCard
                                      key={r.id}
                                      chef={r}
                                      date={date}
                                      hour={hour}
                                      people_qty={people_qty}
                                      history={props.history}
                                      index={++chefI}
                                      setIsPeopleNotSelected={
                                          setIsPeopleNotSelected
                                      }
                                  />
                              ))
                            : results.map((r) =>
                                  r.starting_price <= range ? (
                                      <ChefCard
                                          key={r.id}
                                          chef={r}
                                          date={date}
                                          hour={hour}
                                          people_qty={people_qty}
                                          history={props.history}
                                          index={++chefI}
                                          setIsPeopleNotSelected={
                                              setIsPeopleNotSelected
                                          }
                                      />
                                  ) : null
                              )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Event;
