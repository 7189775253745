import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./FilterModal.css";

const FilterModal = ({
  isOpen,
  onClose,
  onApplyFilters,
  serviceTypes,
  sortBy,
  setSortBy,
  sortOrder,
}) => {
  const [priceRange, setPriceRange] = useState(() => {
    // Obtener el precio de la URL al cargar el modal
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get("price") || "200000", 10); // Valor predeterminado: 200000
  });
  const [selectedServiceTypes, setSelectedServiceTypes] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const types = params.getAll("service_types");
    return types.map((type) => parseInt(type, 10));
  });
  const [isClosing, setIsClosing] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleApplyFilters = () => {
    var params = new URLSearchParams(window.location.search);
    const currentOrdering = params.get("ordering") || ""; // Obtener el ordenamiento actual
    let newOrdering = sortOrder === "asc" ? sortBy : `-${sortBy}`;

    // Verificar si ya hay un guion en el ordenamiento actual para evitar duplicados
    if (currentOrdering.startsWith("-") && newOrdering.startsWith("-")) {
      newOrdering = newOrdering.substring(1); // Eliminar el primer guion
    }

    // Eliminar uno de los guiones si ya hay más de uno en el ordenamiento
    newOrdering = newOrdering.replace(/-{2,}/g, "-");

    params.set("ordering", newOrdering);
    params.set("price", priceRange);
    // Filtrar y obtener solo los tipos de servicio seleccionados únicos
    const uniqueServiceTypes = Array.from(new Set(selectedServiceTypes));

    // Eliminar los parámetros de tipos de servicio existentes
    params.delete("service_types");

    // Obtener los tipos de servicio actuales en los parámetros
    const currentServiceTypes = params.getAll("service_types");

    // Verificar si cada tipo de servicio seleccionado ya está presente en los parámetros
    uniqueServiceTypes.forEach((type) => {
      if (!currentServiceTypes.includes(type.toString())) {
        params.append("service_types", type);
      }
    });
    const queryParams = params.toString();
    const url = `${window.location.pathname}?${queryParams}`;
    window.history.pushState({ path: url }, "", url); // Actualizar la URL del navegador
    const request = {
      params: params,
    };
    onApplyFilters(request);
    onClose();
  };

  useEffect(() => {
    handleApplyFilters();
  }, [sortOrder]);

  const handleClose = () => {
    setIsClosing(true); // Indicar que el modal se está cerrando
    setTimeout(() => {
      onClose();
      setIsClosing(false); // Restablecer el estado después de un tiempo de espera
    }, 300); // Esperar 300 milisegundos para que se complete la animación de cierre
  };

  return (
    <div
      className={`filter-modal ${isOpen ? "open" : ""}`}
      onClick={handleClose}
    >
      <div
        className={`modal-content ${isClosing ? "slide-down" : ""}`}
        style={{ width: windowWidth >= 1024 ? "50%" : "100%" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop: 34,
          }}
        >
          <h2
            style={{
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
              fontFamily: "Nunito Sans",
            }}
          >
            Ordenar por
          </h2>
          <select
            value={sortBy} // Establecer el valor del select como el estado de sortBy
            onChange={(e) => setSortBy(e.target.value)} // Actualizar el estado de sortBy al cambiar la selección
            style={{
              display: "flex",
              padding: "9px 15px",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              border: "none",
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "21px",
              color: "#262626",
            }}
          >
            <option value="menus__price">Precio</option>
            <option value="name">Orden alfabético</option>
          </select>
        </div>
        <hr style={{ marginBottom: "10px" }} />
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <h3
            style={{
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
              textAlign: "left",
              fontFamily: "Nunito Sans",
            }}
          >
            Filtrar por
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  lineHeight: "21px",
                  color: "#262626",
                }}
              >
                Rango de precios
              </label>
              <label
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#9E9E9E",
                }}
              >
                *Por persona
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: 100,
              }}
            >
              <label style={{}}>Hasta</label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 35,
                  width: "100%",
                }}
              >
                <label
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    lineHeight: "16px",
                    color: "#9E9E9E",
                  }}
                >
                  Gs.{" "}
                </label>
                <label
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    lineHeight: "16px",
                    color: "#000000",
                  }}
                >
                  {priceRange !== 0 ? formatPrice(priceRange) : priceRange}
                </label>
              </div>
            </div>
          </div>

          <Slider
            style={{ marginTop: 20 }}
            railStyle={{ backgroundColor: "#262626" }}
            trackStyle={{ backgroundColor: "#CE2F15" }}
            handleStyle={{
              backgroundColor: "#CE2F15",
              border: "solid 2px #CE2F15",
            }}
            min={25000}
            max={500000}
            defaultValue={priceRange}
            step={25000}
            onChange={(e) => {
              setPriceRange(e);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label
            style={{
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
              color: "#000000",
              marginBottom: 8,
            }}
          >
            Tipo de comida
          </label>

          {serviceTypes &&
            serviceTypes.map((type) => (
              <div
                key={type.id}
                style={{ textAlign: "left", marginBottom: "10px" }}
              >
                <input
                  type="checkbox"
                  id={type.id}
                  style={{ marginRight: "12px", accentColor: "#CE2F15" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedServiceTypes([
                        ...selectedServiceTypes,
                        type.id,
                      ]);
                    } else {
                      setSelectedServiceTypes(
                        selectedServiceTypes.filter(
                          (selectedId) => selectedId !== type.id
                        )
                      );
                    }
                  }}
                  checked={selectedServiceTypes.includes(type.id)} // Marcar el checkbox si el tipo de servicio está en la lista seleccionada
                />
                <label
                  htmlFor={type.id}
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    lineHeight: "21px",
                    color: "#262626",
                  }}
                >
                  {type.name}
                </label>
              </div>
            ))}
        </div>
        <hr style={{ marginBottom: "10px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            style={{
              marginRight: "10px",
              padding: "16px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              color: "#CE2F15",
              backgroundColor: "#FFF",
              width: "50%",
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
            }}
            onClick={handleClose}
          >
            Cerrar
          </button>
          <button
            style={{
              padding: "16px 20px",
              backgroundColor: "#CE2F15",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "50%",
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "20px",
            }}
            onClick={handleApplyFilters}
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
