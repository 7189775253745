import "./PaymentForm.css";
import checkCircle from "../assets/new images/check-circle.svg";
import MenuCard from "../components/MenuCard";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import closeIcon from "../assets/images/close-outline.svg";

function PaymentForm() {
  const [showShowCheckout, setShowCheckout] = useState(false);
  const [showShowZimple, setShowZimple] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);
  const bancardUrl =
    process.env.REACT_APP_BANCARD_TEST_MODE.toLowerCase() === "true"
      ? "https://vpos.infonet.com.py:8888/checkout/javascript/dist/bancard-checkout-3.0.0.js"
      : "https://vpos.infonet.com.py/checkout/javascript/dist/bancard-checkout-3.0.0.js";
  const history = useHistory();
  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const menuData = {
    id: 1,
    title: "Parrilla",
    price: "135000.00",
    min_diners: 15,
    max_diners: null,
    courses: [
      {
        id: 1,
        name: "Entrada",
        description:
          "<ul>\r\n<li><strong>Baba Ganoush</strong>&nbsp;con pan &aacute;rabe</li>\r\n<li>Berenjenas asadas al rescoldo</li>\r\n<li>Chorizos Toscano</li>\r\n<li>Matambrito de Cerdo relleno con morrones, provolone y aceitunas negras</li>\r\n<li>Rollitos caprese de&nbsp;<strong>zucchini&nbsp;</strong>con pesto</li>\r\n</ul>",
        image: null,
      },
      {
        id: 2,
        name: "Fondo",
        description:
          "<ul>\r\n<li><strong>Costilla ancha&nbsp;</strong>con 5 horas de cocci&oacute;n.</li>\r\n<li>Tapa Cuadril &amp; zapallo relleno de queso crema y queso azul.</li>\r\n<li><strong>Batatas al Romero&nbsp;</strong>Salsa Picante y&nbsp;<em>criolla</em>.</li>\r\n</ul>",
        image: null,
      },
    ],
    pictures: [
      {
        id: 1,
        picture:
          "https://api.buscochef.app/media/menus/WhatsApp_Image_2021-07-21_at_11.07.14_1qXxzxz.jpeg",
        is_cover: false,
      },
      {
        id: 2,
        picture:
          "https://api.buscochef.app/media/menus/WhatsApp_Image_2021-07-21_at_11.07.17_PkrAm0Z.jpeg",
        is_cover: true,
      },
    ],
    requirements: ["Parrilla limpia"],
    includes: ["Servilletas", "Vajillería"],
    anticipation_time: 72,
    short_description:
      "Saborea jugosas carnes a la parrilla con irresistibles acompañamientos en nuestro menú de grill. Una experiencia culinaria inigualable",
    obs: "",
  };

  function Checkout({ bancardToken, exit }) {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = bancardUrl;
      const runBancard = document.createElement("script");
      runBancard.innerHTML = `Bancard.Checkout.createForm('bancardIframe', '${bancardToken}');`;
      script.onload = () => {
        document.body.appendChild(runBancard);
      };
      document.body.appendChild(script);
    });

    return (
      <div className="modal bigModal shadow1">
        <div className="modal-header redBackground whiteColor">
          <div className="modal-title">Pagar con tarjeta</div>
          <img
            onClick={exit}
            className="modal-close"
            src={closeIcon}
            alt="X"
          ></img>
        </div>
        <div id="bancardIframe" />
      </div>
    );
  }

  function Zimple(props) {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = bancardUrl;
      const runBancard = document.createElement("script");
      runBancard.innerHTML = `Bancard.Zimple.createForm('bancardIframe', '${props.bancardToken}');`;
      script.onload = () => {
        document.body.appendChild(runBancard);
      };
      document.body.appendChild(script);
    });

    return (
      <div className="modal bigModal shadow1">
        <div className="modal-header redBackground whiteColor">
          <div className="modal-title">Pagar con billetera Zimple</div>
          <img
            onClick={props.exit}
            className="modal-close"
            src={closeIcon}
            alt="X"
          ></img>
        </div>
        <div id="bancardIframe" />
      </div>
    );
  }

  return (
    <div
      className="payment-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: windowWidth >= 1024 ? "40%" : "100%",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <div className="payment-content">
        <div className="payment-header">
          <img src={checkCircle} alt="Icono de reserva aprobada" />
          <h2 className="payment-header-title">¡Tu reserva fue aprobada!</h2>
          <p className="payment-header-subtitle">
            Ahora solo queda realizar el pago para confirmar el servicio
          </p>
        </div>

        <h2 className="payment-details-title">Reserva a pagar:</h2>
        <MenuCard
          menuData={menuData}
          key={menuData.id}
          showDes={false}
          nolink={true}
          resume={true}
        />

        <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
          <div className="payment-details-item">
            <p className="payment-details-item-title">Cantidad de personas:</p>
            <span className="payment-details-item-title payment-details-item-value">
              14
            </span>
          </div>
          <div className="payment-details-item">
            <p className="payment-details-item-title">Costo:</p>
            <span className="payment-details-item-title payment-details-item-gs">
              Gs.
              <br /> {formatPrice(450000)}
            </span>
          </div>
          <div className="payment-details-item">
            <p className="payment-details-item-title">
              Coste extra (Emitido por el Chef):
            </p>
            <span className="payment-details-item-title payment-details-item-gs">
              Gs. <br />
              {formatPrice(300000)}
            </span>
          </div>
          <div className="payment-details-item">
            <p className="payment-details-item-total">TOTAL:</p>
            <span className="payment-details-item-total-value payment-details-item-gs">
              Gs. <br />
              {formatPrice(450000 + 300000)}
            </span>
          </div>
        </div>
        <button
          onClick={() => setShowCheckout(true)}
          className="payment-button"
        >
          Pagar reserva con tarjeta
        </button>
        <button onClick={() => setShowZimple(true)} className="payment-button">
          Pagar reserva con celular
        </button>
        {showShowCheckout && (
          <Checkout
            bancardToken={() => {}}
            exit={() => setShowCheckout(false)}
          />
        )}
        {showShowZimple && (
          <Zimple bancardToken={() => {}} exit={() => setShowZimple(false)} />
        )}
      </div>
    </div>
  );
}

export default PaymentForm;
