import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import notificationGif from "../assets/new images/notificationGif.gif";
import logo from "../assets/new images/logo.svg";

const NotificationScreen = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <div
            style={{
              position: windowWidth >= 1024 ? "relative" : "absolute",
              left: windowWidth >= 1024 ? -20 : 20,
              top: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 37,
              height: 37,
              backgroundColor: "#f0f0f0",
              borderRadius: "50%",
              fontSize: 20,
              fontWeight: "700",
            }}
          >
            x
          </div>
        </Link>
      </div>
      <img
        src={notificationGif}
        alt="Notification Image"
        style={{ width: 110, height: 110, marginTop: 100 }}
      />
      <h1
        style={{
          color: "#CE2F15",
          textAlign: "center",
          fontSize: 35,
          fontWeight: "700",
          lineHeight: "34px",
          fontFamily: "Nunito Sans",
        }}
      >
        A un paso de una nueva experiencia
      </h1>
      <h2
        style={{
          color: "#262626",
          textAlign: "center",
          fontSize: 18,
          fontWeight: "700",
          lineHeight: "22px",
          width: 350,
          fontFamily: "Nunito Sans",
        }}
      >
        El chef ya fue notificado,
        <br /> ahora solo queda esperar su respuesta. Quedate pendiente a tu
        correo.
      </h2>
      <p
        style={{
          color: "#262626",
          textAlign: "center",
          fontSize: 12,
          fontWeight: "400",
          lineHeight: "16px",
          width: 190,
          marginTop: 150,
          marginBottom: 35,
        }}
      >
        Ante cualquier consulta escribinos al WhatsApp 0981 123 456
      </p>
      <img
        src={logo} // Ruta del logo
        alt="Logo"
        style={{ width: 150, objectFit: "cover" }}
      />
    </div>
  );
};

export default NotificationScreen;
