import defaultProps from "./defaultProps";

export default function UsersIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 8.375V7.625C6.375 7.22718 6.21696 6.84564 5.93566 6.56434C5.65436 6.28304 5.27282 6.125 4.875 6.125H1.875C1.47718 6.125 1.09564 6.28304 0.81434 6.56434C0.533035 6.84564 0.375 7.22718 0.375 7.625V8.375"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 4.625C4.20343 4.625 4.875 3.95343 4.875 3.125C4.875 2.29657 4.20343 1.625 3.375 1.625C2.54657 1.625 1.875 2.29657 1.875 3.125C1.875 3.95343 2.54657 4.625 3.375 4.625Z"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.625 8.37496V7.62496C8.62475 7.2926 8.51413 6.96975 8.31051 6.70707C8.10689 6.4444 7.8218 6.25679 7.5 6.17371"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1.67371C6.32266 1.75632 6.60864 1.94397 6.81286 2.20707C7.01709 2.47018 7.12794 2.79377 7.12794 3.12683C7.12794 3.45989 7.01709 3.78349 6.81286 4.04659C6.60864 4.30969 6.32266 4.49734 6 4.57996"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

UsersIcon.defaultProps = defaultProps;
