import { useHistory } from "react-router";
import alertIcon from "../assets/images/alert-circle-outline.svg";

const ErrorPage = (props) => {
  let history = useHistory();
  return (
    <div className="error-page">
      <div className="login is-flex is-flex-direction-column is-align-items-center ">
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <img className="logo-home my-6" src={alertIcon} alt="alert icon" />
          <h1
            className="baseXL-black-text"
            style={{
              color: "white",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            Algo anda mal
          </h1>
          <p
            className="mt-4 mb-4"
            style={{
              color: "white",
              textAlign: "center",
              lineHeight: "20px",
              width: "200px",
            }}
          >
            {props.errorStr}
          </p>
        </div>
        <div>
          <button
            onClick={() => history.push({ pathname: "/confirmation" })}
            className="white-button mt-2 h-cursor zoom my-first-step"
          >
            Volver Atras
          </button>
        </div>
        <div>
          <button
            onClick={() => history.push({ pathname: "/" })}
            className="volver-inicio-button mt-2 h-cursor zoom my-other-step"
          >
            Volver al Inicio
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
