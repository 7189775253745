import defaultProps from "./defaultProps";

export default function ClockIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_276_499)">
        <path
          d="M6 10.9999C8.76142 10.9999 11 8.7613 11 5.99988C11 3.23845 8.76142 0.999878 6 0.999878C3.23858 0.999878 1 3.23845 1 5.99988C1 8.7613 3.23858 10.9999 6 10.9999Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 2.99988V5.99988L8 6.99988"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_276_499">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

ClockIcon.defaultProps = defaultProps;
