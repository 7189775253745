import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MenuCard from "../components/MenuCard";
import minus from "../assets/new images/minus-circle.svg";
import plus from "../assets/new images/plus-circle.svg";
import { useQuery } from "../hooks";
import API from "../api";
import loader from "../assets/new images/buscoChefLoader.gif";
import checkGif from "../assets/new images/checkGif.gif";
import { min } from "date-fns";

const EditBoooking = () => {
  const history = useHistory();

  let query = useQuery();
  const [data, setData] = useState({});
  const [peopleCount, setPeopleCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesFiles, setImagesFiles] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [formDataToSend, setFormDataToSend] = useState({});

  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Sumar 1 al mes ya que en JavaScript los meses van de 0 a 11
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  function reformatDate(dateStr) {
    const [year, month, day] = dateStr.split("-").map(Number);
    return `${day}-${month.toString().padStart(2, "0")}-${year}`;
  }

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImagesFiles(files);
    const images = files.map((file) => URL.createObjectURL(file));
    setNewImages((prevImages) => [...prevImages, ...images]);
  };

  const deleteImage = (item) => {
    setImagesToDelete((prevImages) => [...prevImages, item]);
    const newImages = selectedImages.filter((value) => value !== item);
    setSelectedImages(newImages);
    console.log(imagesToDelete);
  };

  const deleteNewImage = (item) => {
    const updateImages = newImages?.filter((value) => value !== item);
    setNewImages(updateImages);
  };

  const showConfirmationModal = (message) => {
    setConfirmationMessage(message);
    setShowModal(true);

    // Ocultar el modal después de unos segundos (por ejemplo, 3 segundos)
    setTimeout(() => {
      setShowModal(false);
      setConfirmationMessage("");
      history.push("/"); // Redirigir a la pantalla principal después de ocultar el modal
    }, 3000); // 3000 milisegundos = 3 segundos
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);

  const getCurrentBooking = async () => {
    try {
      const currentBooking = await API.getReservation(
        query.get("reservation").replace("/", "")
      );
      console.log(currentBooking);
      setData(currentBooking);
      setPeopleCount(currentBooking.diners_qty);
      setSelectedImages(currentBooking.reservation_images.map((i) => i));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBlur = () => {
    if (peopleCount < data.menu.min_diners) {
      setPeopleCount(data.menu.min_diners);
      alert(
        `No puedes disminuir la cantidad de comensales: ${data.menu.min_diners}`
      );
    }
  };

  function isDateAtLeast3DaysLater(date1, date2) {
    const [day1, month1, year1] = date1.split("-").map(Number);
    const [day2, month2, year2] = date2.split("-").map(Number);

    const date1InDays =
      new Date(year1, month1 - 1, day1).valueOf() / (1000 * 60 * 60 * 24);
    const date2InDays =
      new Date(year2, month2 - 1, day2).valueOf() / (1000 * 60 * 60 * 24);

    const daysDiff = date1InDays - date2InDays;
    return daysDiff >= 3;
  }

  const updateData = () => {
    const formData = new FormData();
    // Agregar las imágenes al FormData
    imagesFiles.forEach((file, index) => {
      formData.append(`uploaded_images`, file);
    });
    /*const imagesToDeleteParse = imagesToDelete.map((i) => JSON.stringify(i));
    formData.append(`images_to_delete`, imagesToDeleteParse);*/
    // Agregar las imágenes a eliminar
    imagesToDelete.forEach((file, index) => {
      formData.append(`images_to_delete`, JSON.stringify(file));
    });
    formData.append("diners_qty", peopleCount);
    formData.append("total", (data.total / data.diners_qty) * peopleCount);
    setFormDataToSend(formData);
  };

  const handleSubmit = async () => {
    try {
      const currentDate = getFormattedDate();
      const reformattedDate = reformatDate(data.date);
      const isAtLeastThreeDaysLater = isDateAtLeast3DaysLater(
        reformattedDate,
        currentDate
      );
      console.log(reformattedDate, currentDate);
      if (isAtLeastThreeDaysLater) {
        const response = await API.editReservation(data.id, formDataToSend);
        console.log("Reserva editada:", response);
        setShowError("");
        showConfirmationModal("Se ha editado la reserva correctamente.");
        return true;
      } else {
        setShowError(
          "La edición solo está permitida hasta 3 dias antes de la reserva."
        );
        return false;
      }

      // Aquí puedes manejar la respuesta del servidor, mostrar una notificación, redirigir al usuario, etc.
    } catch (error) {
      console.error(error.detail);
      setShowError(error.detail);
      return false;
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje de error al usuario.
    }
  };

  useEffect(() => {
    getCurrentBooking();
  }, []);

  useEffect(() => {
    updateData();
  }, [peopleCount, imagesFiles, imagesToDelete]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        minHeight: "100vh",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: windowWidth >= 1024 ? "50%" : "100%",
          }}
        >
          <img src={loader} alt="loader" style={{ width: 182 }} />
          <p
            style={{
              fontSize: 18,
              fontWeight: "700",
              lineHeight: " 22px",
              color: "#CE2F15",
            }}
          >
            Cargando...
          </p>
        </div>
      ) : (
        <div style={{ width: windowWidth >= 1024 ? "50%" : "100%" }}>
          <h2 className="bookingTitle">Editar Reserva</h2>
          <MenuCard
            menuData={data.menu}
            key={data.menu.id}
            showDes={false}
            nolink={true}
            resume={true}
          />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              height: 1,
              borderRadius: 21,
              background: " #D9D9D9",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div className="resumen-container">
              <span className="resumen-title">Fecha:</span>
              <span className="resumen-text">{reformatDate(data.date)}</span>
            </div>
            <div className="resumen-container">
              <span className="resumen-title">Horario:</span>
              <span className="resumen-text">
                {data.time_of_day === 1
                  ? "11:00 a.m. - 14:00 p.m."
                  : data.time_of_day === 2
                  ? "15:30 p.m. - 17:30 p.m."
                  : "18:30 p.m. - 21:30 p.m."}
              </span>
            </div>
            <div
              className="resumen-container"
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: 5,

                height: 35,
                padding: "10px 0",
              }}
            >
              <span className="resumen-title">Cantidad de personas:</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    textAlign: "center",
                    border: "none",
                    height: 35,
                    width: 50,
                    fontSize: 12,
                    fontWeight: "700",
                    color: "#262626",
                    backgroundColor: "#FFF",
                  }}
                  type="number"
                  value={peopleCount}
                  onChange={(e) => {
                    setPeopleCount(e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "none",
                    width: 15,
                    height: 15,
                    padding: 0,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (peopleCount < data.menu.max_diners) {
                      setPeopleCount((prev) =>
                        setPeopleCount(parseInt(prev) + 1)
                      );
                    }
                  }}
                >
                  <img src={plus} alt="plus" />
                </button>
              </div>
            </div>
            <div className="resumen-container">
              <span className="resumen-title">Lugar del evento:</span>
              <span className="resumen-text">
                {data.event_location == 1 ? "Aire libre" : "Techo Cerrado"}
              </span>
            </div>
            <div className="resumen-container">
              <span className="resumen-title">Dirección:</span>
              <span className="resumen-text">{data.address}</span>
            </div>
            <div style={{ marginBottom: 10, position: "relative" }}>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#000000",
                }}
              >
                Fotos del lugar
              </div>
              <div
                style={{
                  fontSize: 10,
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#969B91",
                  marginBottom: 10,
                }}
              >
                Si no tenés a mano, podés adjuntar las fotos después
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  rowGap: 20,
                }}
              >
                {selectedImages?.map((image, index) => (
                  <div
                    style={{
                      width: "50%",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        left: "10%",
                        top: "22%",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        zIndex: 9,
                        color: "#fff",
                        padding: 12,
                      }}
                      onClick={() => deleteImage(image)}
                    >
                      X
                    </div>

                    <img
                      key={index}
                      src={image.image}
                      alt={`Image-${index}`}
                      style={{
                        width: "100%",
                        height: windowWidth >= 1024 ? 150 : 110,
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                ))}
                {newImages?.map((image, index) => (
                  <div
                    style={{
                      width: "50%",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        left: "10%",
                        top: "22%",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        zIndex: 9,
                        color: "#fff",
                        padding: 12,
                      }}
                      onClick={() => deleteNewImage(image)}
                    >
                      X
                    </div>

                    <img
                      key={index}
                      src={image}
                      alt={`Image-${index}`}
                      style={{
                        width: "100%",
                        height: windowWidth >= 1024 ? 150 : 110,
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div
                onClick={() =>
                  document.querySelector('input[type="file"]').click()
                }
                style={{
                  height: 110,
                  borderRadius: 4,
                  border: "1px dashed #ACACAC",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                  padding: 25,
                  cursor: "pointer",
                  marginTop: 20,
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "400",
                    lineHeight: "16px",
                    color: "#ACACAC",
                  }}
                >
                  Seleccioná la foto desde tu galería o tomale una
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 8,
                    fontWeight: "400",
                    color: "rgba(172, 172, 172, 0.50)",
                  }}
                >
                  JPG, PNG or PDF, archivo no más de 10MB
                </p>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
                multiple
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontSize: 16,
                fontWeight: "800",
                color: "#000000",
              }}
            >
              Precio total
            </span>
            <span
              style={{
                fontSize: 16,
                fontWeight: "800",
                color: "#000000",
              }}
            >
              Gs. {formatPrice((data.total / data.diners_qty) * peopleCount)}
            </span>
          </div>

          <button
            type="submit"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 17,
              backgroundColor: "#CE2F15",
              color: "#FFF",
              fontSize: 18,
              fontWeight: "700",
              lineHeight: "22px",
              borderRadius: 8,
            }}
            onClick={handleSubmit}
          >
            Editar reserva
          </button>
          <p
            style={{
              display: !showError ? "none" : "block",
              color: "#CE2F15",
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "14px",
              marginTop: 0,
            }}
          >
            {showError}
          </p>
        </div>
      )}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              padding: 20,
              borderRadius: 8,
              maxWidth: 400,
              textAlign: "center",
              color: "#27A576",
              fontSize: 18,
              fontWeight: "700",
              lineHeight: "22px",
              margin: 20,
            }}
          >
            <img src={checkGif} alt="Check Image" style={{ width: 150 }} />
            <p>{confirmationMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBoooking;
