import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import loader from '../assets/lottie/search.json';
const Loading = (props) => {
    const container = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: require(`../assets/lottie/search.json`),
        });
    }, []);

    return (
        <div
            style={{
                top: '50%',
                position: 'inherit',
                display: 'flex',
                zIndex: 0,
            }}
        >
            <div
                className="container is-flex is-align-items-center"
                ref={container}
                style={{ maxWidth: '300px' }}
            ></div>
        </div>
    );
};

export default Loading;
