import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import ChefScreen from "./pages/ChefScreen";
import MenuPicker from "./pages/MenuPicker";
import OrdeReady from "./pages/OrdeReady";
import Confirmation from "./pages/Confirmation";
import Filter from "./pages/Filter";
import Workshops from "./pages/Workshops";
import Help from "./components/Help";
import Policy from "./pages/Policy";
import HelpPeople from "./pages/helppeople";
import ErrorPage from "./components/ErrorPage";
import { Carousel } from "react-responsive-carousel";
import ChefDetailsScreen from "./pages/ChefDetailsScreen";
import BioScreen from "./pages/BioScreen";
import MenuDetails from "./pages/MenuDetails";
import Event from "./pages/Event";
import BookingForm from "./pages/BookingForm";
import NotificationScreen from "./pages/NotificationScreen";
import AddExtraCost from "./pages/AddExtraCost";
import PaymentForm from "./pages/PaymentForm";
import PaymentConfirm from "./pages/PaymentConfirm";
import EditBoooking from "./pages/EditBooking";
import RejectBooking from "./pages/RejectBooking";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={ChefScreen}></Route>
        <Route exact path="/help" component={Help}></Route>
        <Route exact path="/search" component={Event}></Route>
        <Route exact path="/select-menu" component={ChefDetailsScreen}></Route>
        <Route exact path="/bio" component={BioScreen}></Route>
        <Route exact path="/order-ready" component={MenuDetails}></Route>
        <Route exact path="/booking" component={BookingForm}></Route>
        <Route exact path="/edit-booking" component={EditBoooking}></Route>
        <Route path="/notification" component={NotificationScreen} />
        <Route path="/notification-extra-cost" component={AddExtraCost} />
        <Route path="/notification-reject" component={RejectBooking} />
        <Route path="/payment" component={PaymentForm} />
        <Route path="/payment-confirm" component={PaymentConfirm} />
        <Route exact path="/workshops" component={Workshops}></Route>
        <Route exact path="/privacy-policy" component={Policy}></Route>
        <Route exact path="/help-people" component={HelpPeople}></Route>
        <Route
          path="/faq"
          component={() => {
            window.location.replace("https://buscochef.app/faq/");
            return null;
          }}
        />
        <Route exact path="/confirmation" component={Confirmation}></Route>
        <Route exact path="/filter" component={Filter}></Route>
        <Route path="/error" component={ErrorPage}></Route>
      </Switch>
    </Router>
  );
}

export default App;
