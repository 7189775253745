import axios from "axios";

class API {
  static _instance;
  constructor() {
    if (!API._instance) {
      API._instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          common: {
            "Content-Type": "application/json",
          },
        },
      });
    }
  }

  async getServiceTypes() {
    try {
      const response = await API._instance.get("/service-types/");
      return Promise.resolve(response.data);
    } catch (err) {
      // TODO: Better error handling
      return Promise.reject("connection_error");
    }
  }

  /**
   * Makes a search
   * @param {Object} params object of query params to perform search.
   * Valid params are service_type, date and people_qty
   */
  async search(params) {
    try {
      const response = await API._instance.get("/search/", {
        params: params,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      // TODO: Better error handling
      return Promise.reject("connection_error");
    }
  }

  async getServiceProviders(filters = {}) {
    try {
      const response = await API._instance.get(
        "/service-providers/?ordering=name",
        filters
      );
      return Promise.resolve(response.data);
    } catch (err) {
      // Manejar los errores de manera adecuada
      console.error("Error fetching service providers:", err);
      return Promise.reject("Error de conexión");
    }
  }

  async getServiceProvider(id) {
    try {
      const response = await API._instance.get(`/service-providers/${id}/`);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject("connection_error");
    }
  }

  async createOrder(data) {
    try {
      const response = await API._instance.post("/orders/", data);
      return Promise.resolve(response.data);
    } catch (err) {
      // TODO: Better error handling
      let message;
      if (err.response.status === 400) {
        const error = err.response.data;
        if (error.non_field_errors.length > 0) {
          if (
            error.non_field_errors.find(
              (x) =>
                x === "Quantity of diners is below minimum allowed for menu."
            )
          ) {
            message =
              "La cantidad de personas es menor al mínimo requerido por el Chef.";
          }
        }
      } else if (err.response.status === 500) {
        const error = err.response.data;
        message = "Error de conexión con el servidor";
      }
      return Promise.reject(message);
      console.log(err);
    }
  }

  async sendReservation(formData) {
    try {
      const response = await API._instance.post("/reservations/", formData);
      return Promise.resolve(response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        // Si la respuesta del servidor tiene datos de error, los mostramos
        console.error("Error al enviar la reserva:", err.response.data);
        return Promise.reject(err.response.data);
      } else {
        // Si no hay datos de error en la respuesta del servidor, mostramos el error general
        console.error("Error al enviar la reserva:", err);
        return Promise.reject("Error al enviar la reserva");
      }
    }
  }

  async sendExtraCost(formData) {
    try {
      const response = await API._instance.post(
        "/reservations/extra-cost/",
        formData
      );
      return Promise.resolve(response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        // Si la respuesta del servidor tiene datos de error, los mostramos
        console.error("Error al enviar la reserva:", err.response.data);
        return Promise.reject(err.response.data);
      } else {
        // Si no hay datos de error en la respuesta del servidor, mostramos el error general
        console.error("Error al enviar la reserva:", err);
        return Promise.reject("Error al enviar la reserva");
      }
    }
  }

  async editReservation(id, formData) {
    try {
      const response = await API._instance.put(
        `/reservations/${id}/`,
        formData
      );
      return Promise.resolve(response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        // Si la respuesta del servidor tiene datos de error, los mostramos
        console.error("Error al editar la reserva:", err.response.data);
        return Promise.reject(err.response.data);
      } else {
        // Si no hay datos de error en la respuesta del servidor, mostramos el error general
        console.error("Error al editar la reserva:", err);
        return Promise.reject("Error al editar la reserva");
      }
    }
  }

  async getReservation(id) {
    try {
      const response = await API._instance.get(`/reservations/${id}`);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject("connection_error");
    }
  }

  async sendReservationCheck(formData) {
    try {
      const response = await API._instance.post(
        "/reservations/check/",
        formData
      );
      return Promise.resolve(response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        // Si la respuesta del servidor tiene datos de error, los mostramos
        console.error("Error al enviar la reserva:", err.response.data);
        return Promise.reject(err.response.data);
      } else {
        // Si no hay datos de error en la respuesta del servidor, mostramos el error general
        console.error("Error al enviar la reserva:", err);
        return Promise.reject("Error al enviar la reserva");
      }
    }
  }

  async getCities() {
    try {
      const response = await API._instance.get("/cities");
      return Promise.resolve(response.data);
    } catch (err) {
      console.error("Error fetching cities:", err);
      return Promise.reject("Error al obtener la lista de ciudades");
    }
  }

  async getLocalities() {
    try {
      const response = await API._instance.get("/localities");
      return Promise.resolve(response.data);
    } catch (err) {
      console.error("Error fetching localities:", err);
      return Promise.reject("Error al obtener la lista de localidades");
    }
  }

  async sendRejectReservation(id, reason) {
    try {
      const response = await API._instance.post(
        `/reservations/${id}/cancel/`,
        reason
      );
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err.response.data);
    }
  }
}

export default new API();
