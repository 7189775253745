import defaultProps from "./defaultProps";

export default function AlertIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_833_302)">
        <path
          d="M6.99999 12.8334C10.2217 12.8334 12.8333 10.2217 12.8333 7.00008C12.8333 3.77842 10.2217 1.16675 6.99999 1.16675C3.77833 1.16675 1.16666 3.77842 1.16666 7.00008C1.16666 10.2217 3.77833 12.8334 6.99999 12.8334Z"
          fill={color}
        />
        <path
          d="M7 4.66675V7.00008"
          stroke="white"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.33325H7.00583"
          stroke="white"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_833_302">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

AlertIcon.defaultProps = defaultProps;
