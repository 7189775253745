import defaultProps from "./defaultProps";

export default function EyeIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M7.66666 3.99992C7.66666 3.99992 6.33332 1.33325 3.99999 1.33325C1.66666 1.33325 0.333323 3.99992 0.333323 3.99992C0.333323 3.99992 1.66666 6.66659 3.99999 6.66659C6.33332 6.66659 7.66666 3.99992 7.66666 3.99992Z"
        stroke={color}
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 5C3.44772 5 3 4.55228 3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4C5 4.55228 4.55228 5 4 5Z"
        stroke={color}
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EyeIcon.defaultProps = defaultProps;
