import React from 'react';
import Construction from '../components/Construction';
import { useHistory } from 'react-router';
const Workshops = () => {
    let history = useHistory();
    return (
        <div>
            <div>
                <div className="is-flex is-align-items-center is-justify-content-center ">
                    <Construction />
                </div>
                <div
                    className=" is-flex is-align-items-center is-justify-content-center "
                    style={{
                        fontSize: '25px',
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                >
                    En Construcción
                </div>
                <p
                    className="is-flex is-align-items-center is-justify-content-center mx-4"
                    style={{
                        color: 'white',
                        textAlign: 'center',
                        lineHeight: '30px',
                    }}
                >
                    Seguimos construyendo el área de talleres, mientras podes
                    realizar reservas de Chef en nuestra área de reservas
                </p>
                <div className="is-flex is-align-items-center is-justify-content-center mx-4">
                    <button
                        onClick={() => history.push({ pathname: '/' })}
                        className="white-button my-5 h-cursor zoom"
                    >
                        Volver
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Workshops;
