import React from 'react';
import { useHistory } from 'react-router';
import help from '../assets/images/people-help.png';
const HelpPeople = () => {
    let history = useHistory();
    const goBack = () => {
        history.goBack();
    };
    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div
                className="modal-content white-bg animate__animated animate__fadeInUp px-4 is-flex is-flex-direction-column is-align-items-center"
                style={{
                    borderRadius: '10px',
                    width: '90%',
                    maxWidth: '300px',
                }}
            >
                <div style={{ padding: '10px' }}>
                    <div
                        className="title white-bg is-flex "
                        style={{ paddingTop: '30px' }}
                    >
                        Con Cuidado!
                    </div>
                    <img
                        src={help}
                        alt="Ayuda"
                        style={{ paddingBottom: '30px' }}
                    />
                    <div className="baseXS-text">
                        No olvides de seleccionar la cantidad de personas y el
                        horario de tu evento
                    </div>
                    <button
                        className="red-button h-cursor zoom "
                        onClick={() => goBack()}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HelpPeople;
