import React from "react";
import "./CustomInput.css"; // Estilo CSS para el input

const CustomInput = ({ label, value, onChange, require, placeholder }) => {
  return (
    <div className="custom-input-container">
      <label className="input-label">
        {label}
        {require && <span className="require">*</span>}
      </label>
      <input
        className="custom-input"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
