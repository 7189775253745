import Appbar from "../components/Appbar";
import UsersIcon from "../assets/new icons/UsersIcon";
import CheckIcon from "../assets/new icons/CheckIcon";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CustomCarousel.css";
import AlertIcon from "../assets/new icons/AlertIcon";
import EyeIcon from "../assets/new icons/EyeIcon";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

const MenuDetails = (props) => {
  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const menuData = props.location.state.menuData;
  const data = props.location.state.data;

  const images = menuData.pictures;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Obtener el ancho de la pantalla

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Actualizar el ancho de la pantalla al cambiar su tamaño
    };

    window.addEventListener("resize", handleResize); // Escuchar cambios en el tamaño de la pantalla

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiar el evento al desmontar el componente
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: windowWidth >= 1024 ? "center" : "flex-start",
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <Appbar />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: windowWidth >= 1024 ? "50%" : "100%",
        }}
      >
        <div style={{ padding: "0px 20px", marginTop: 20 }}>
          <Carousel showThumbs={true} showStatus={false} showArrows={false}>
            {images.map((image, index) => (
              <img
                key={image.id}
                src={image.picture}
                alt={`Image ${index + 1}`}
                style={{
                  maxHeight: 322,
                  //minWidth: 76,
                  borderRadius: 7,
                  objectFit: "cover",
                }}
              />
            ))}
          </Carousel>

          <div
            style={{
              color: "#333",
              fontSize: 12,
              fontWeight: "700",
              lineHeight: "22px",
              marginTop: "-26px",
            }}
          >
            {data.name}
          </div>

          <div
            style={{
              color: "#262626",
              fontSize: 25,
              fontWeight: "700",
              lineHeight: "33px",
            }}
          >
            {menuData.title}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              columnGap: 7,
            }}
          >
            <strong
              style={{
                color: "#CE2F15",
                fontSize: 18,
                fontWeight: "700",
                lineHeight: "22px",
              }}
            >
              Gs. {formatPrice(menuData.price)}
            </strong>
            <p
              style={{
                color: "#262626",
                fontSize: 12,
                fontWeight: "600",
                lineHeight: "16px",
              }}
            >
              Por persona
            </p>
            <p>•</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#767676",
                fontSize: 12,
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              <UsersIcon size={13} color="#767676" />
              Mínimo {menuData.min_diners} personas
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {menuData.courses.map((c) => (
                <div
                  key={c.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "800",
                      color: "#111",
                      lineHeight: "16px",
                    }}
                  >
                    {c.name}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: c.description }} />
                </div>
              ))}

              {/* Agrega aquí el contenido del chef */}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#F7F4F8",
            height: 4,
            width: "100%",
            margin: "15px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 5,
            color: "#262626",
            fontSize: 16,
            fontWeight: "800",
            lineHeight: "16px",
            marginBottom: 5,
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#55D8A8",
              height: 14,
              width: 14,
            }}
          >
            <CheckIcon color="#27A576" size={11} />
          </div>
          Lo que incluye el servicio
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          {menuData?.includes.map((m) => (
            <li
              key={m}
              style={{
                minWidth: 108,
                color: "#000000",
                fontSize: 12,
                fontWeight: "600",
                lineHeight: "16px",
                paddingLeft: 27,
              }}
            >
              {m}
            </li>
          ))}
        </div>
        <div
          style={{
            backgroundColor: "#F7F4F8",
            height: 4,
            width: "100%",
            margin: "15px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 5,
            color: "#262626",
            fontSize: 16,
            fontWeight: "800",
            lineHeight: "16px",
            marginBottom: 5,
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              height: 14,
              width: 14,
            }}
          >
            <AlertIcon size={14} color="#FB8700" />
          </div>
          Requerimientos
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          {menuData?.requirements.map((m) => (
            <li
              key={m}
              style={{
                minWidth: 108,
                color: "#000000",
                fontSize: 12,
                fontWeight: "600",
                lineHeight: "16px",
                paddingLeft: 27,
              }}
            >
              {m}
            </li>
          ))}
        </div>
        <div
          style={{
            backgroundColor: "#F7F4F8",
            height: 4,
            width: "100%",
            margin: "15px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 5,
            color: "#262626",
            fontSize: 16,
            fontWeight: "800",
            lineHeight: "16px",
            marginBottom: 5,
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#262626",
              height: 14,
              width: 14,
            }}
          >
            <EyeIcon size={8} color="#fff" />
          </div>
          Observaciones
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          <div
            style={{
              color: "#000000",
              fontSize: 14,
              fontWeight: "600",
              lineHeight: "16px",
              paddingLeft: 20,
            }}
          >
            {menuData.obs ? menuData.obs : "Ninguno"}
          </div>
        </div>
        <Link
          to={{
            pathname: "/booking",
            state: { menuData: menuData, data: data },
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 17,
            backgroundColor: "#CE2F15",
            color: "#FFF",
            fontSize: 18,
            fontWeight: "700",
            lineHeight: "22px",
            borderRadius: 8,
            margin: "35px 15px 20px",
            textDecoration: "none",
          }}
        >
          Solicitar servicio
        </Link>
      </div>
    </div>
  );
};

export default MenuDetails;
