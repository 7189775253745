import defaultProps from "./defaultProps";

export default function CheckIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62962 3.27783L4.63888 8.26857L2.37036 6.00005"
        stroke={color}
        strokeWidth="0.907407"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CheckIcon.defaultProps = defaultProps;
