import React from "react";
import { Link } from "react-router-dom";
import UsersIcon from "../assets/new icons/UsersIcon";
import tag from "../assets/new images/tag.svg";

const MenuCard = ({ menuData, showDes, nolink, resume, data }) => {
  const formatPrice = (price) => {
    if (!price) return ""; // Manejar el caso de un precio nulo o indefinido
    return parseFloat(price).toLocaleString("es-PY", {
      // 'es-PY' para español de Paraguay
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const menuCardStyles = {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E0E0E0",
    gap: 13,
    textDecoration: "none" /* Quita la subrayado predeterminado */,
    cursor: "pointer" /* Cambia el cursor al pasar sobre el enlace */,
  };

  const imageStyles = {
    height: 100,
    width: 150,
    borderRadius: "4px",
    objectFit: "cover",
  };

  const contentStyles = {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    width: "100%",
    textAlign: "left",
  };

  const truncarTexto = (texto, limite) => {
    if (texto.length <= limite) {
      return texto;
    }
    return texto.slice(0, limite) + "...";
  };

  const textoTruncado = truncarTexto(menuData?.short_description, 70);

  return (
    <Link
      to={{
        pathname: "/order-ready",
        state: { menuData: menuData, data: data },
      }}
      onClick={(event) => {
        if (nolink) {
          event.preventDefault();
        }
      }}
      style={{
        display: "flex",
        gap: 13,
        textDecoration: "none",
        borderBottom: showDes ? "1px solid #E0E0E0" : "none",
        //width: "100%",
        alignItems: resume ? "center" : "flex-start",
      }}
    >
      <img
        src={menuData.pictures[0]?.picture}
        alt="Imagen del menú"
        style={imageStyles}
      />

      <div style={contentStyles}>
        <h4
          style={{
            margin: 0,
            padding: 0,
            color: "#000",
            fontSize: 16,
            fontWeight: "600",
            fontFamily: "Nunito Sans",
          }}
        >
          {menuData.title}
        </h4>
        <p
          style={{
            display: showDes ? "block" : "none",
            margin: 0,
            padding: 0,
            color: "#969B91",
            fontSize: 12,
            fontWeight: "400",
            lineHeight: "16px",
            height: 32,
          }}
        >
          {textoTruncado}
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <p
            style={{
              display: resume ? "none" : "flex",
              alignItems: "center",
              gap: 2,
              margin: 0,
              padding: 0,
              color: "#000000",
              fontSize: 12,
              fontWeight: "400",
              lineHeight: "14px",
            }}
          >
            <UsersIcon size={12} color="#111111" />
            {`Mínimo ${menuData.min_diners} personas`}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              margin: 0,
              padding: 0,
              color: "#000000",
              fontSize: 12,
              fontWeight: "400",
              lineHeight: "14px",
            }}
          >
            <img src={tag} alt="tag" style={{ width: 12, height: 12 }} />
            <strong
              style={{
                fontWeight: "600",
              }}
            >
              {`Gs. ${formatPrice(menuData.price)}`}
            </strong>{" "}
            por persona
          </div>
          <p
            style={{
              display: resume ? "none" : "block",
              margin: 0,
              padding: 0,
              marginBottom: 10,
              color: "#CE2F15",
              fontSize: 12,
              fontWeight: "400",
              lineHeight: "14px",
            }}
          >
            Reservar con {menuData.anticipation_time} hs de anticipación
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MenuCard;
